import { Box } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { networkAPI } from 'api/NetworkAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import { useIsComponentMounted } from 'hooks/util'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IError } from 'types/error'
import { NetworkId } from 'types/ids'
import { INetworkManager } from 'types/networkInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { fuseFiltering } from 'utils/utils'
import { InputField } from '../InputField'

/** @notExported */
interface IManagerPickerProps {
  /** The current value. */
  value: INetworkManager[]
  /** The network ID that managers are for. */
  networkId?: NetworkId
  /** Action to do when the value changes. */
  onChange: (newValue: INetworkManager[] | null) => void
  /** The label text. */
  label?: string
  /** Whether the field is required. */
  required?: boolean
  /** Whether the field is disabled. */
  disabled?: boolean
}

/**
 * Network manager picker component.
 *
 * @returns Component for picking network managers.
 * @notExported
 */
const ManagersPicker: React.FC<IManagerPickerProps> = ({
  value,
  networkId,
  onChange,
  label = 'Owner',
  required = false,
  disabled = false,
}) => {
  const isComponentMounted = useIsComponentMounted()
  const [items, setItems] = useState<INetworkManager[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [backendError, setBackendError] = useState<IError>()
  const { i18n } = useTranslation()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      try {
        if (!disabled) {
          if (items.length > 0) return
          setLoading(true)
          const managers = await networkAPI.getPosibleManagers(controller)
          if (!isComponentMounted.current) return
          setItems(managers)
        }
      } catch (error) {
        setBackendError(error as IError)
      } finally {
        setLoading(false)
      }
    })()

    return () => {
      controller.abort()
    }
  }, [networkId])

  const getAccountName = (item: INetworkManager) => {
    if (item.Account.Person) {
      return `${item.Account.Person.firstName} ${item.Account.Person.lastName}`
    } else {
      return item.Account.email
    }
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <Autocomplete
      loading={loading}
      disabled={disabled}
      value={value}
      onChange={(_event, newValue) => {
        if (typeof newValue !== 'string') {
          onChange(newValue)
        }
      }}
      filterOptions={(options, { inputValue }) =>
        fuseFiltering(options, inputValue, ['Account.Person.firstName', 'Account.Person.lastName', 'Account.email'])
      }
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={items}
      getOptionLabel={option => {
        return getAccountName(option)
      }}
      isOptionEqualToValue={(option, value) => {
        return option.accountId === value.accountId
      }}
      renderOption={(props, option) => {
        return (
          <Box component="li" {...props} key={option.id}>
            {getAccountName(option)}
          </Box>
        )
      }}
      renderInput={params => (
        <InputField
          multiline
          {...params}
          size="small"
          fullWidth
          margin="dense"
          variant="outlined"
          insetLabel
          label={label}
          InputLabelProps={{ shrink: true }}
          required={required}
        />
      )}
      groupBy={option => {
        if (option && option.Account.Organization) {
          return chooseDBTranslation(i18n, option.Account.Organization).name
        }
        return ''
      }}
      fullWidth
      multiple
    />
  )
}

export default ManagersPicker
