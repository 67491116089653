import { IUpload } from 'types/upload'
import { SubAPI } from './API'
import { IAssignmentAttachment, IProposalAttachment } from 'types/assignmentInterfaces'

/**
 * Files API
 * @notExported
 */
class FilesAPI extends SubAPI {
  /**
   * Upload assignment attachment file.
   *
   * @param file - File data.
   * @param attachmentType - Type of attachment.
   * @param description - Attachment description.
   * @param language - File language.
   * @returns Attachment data.
   */
  public async createAttachment(
    file: File,
    attachmentType: string,
    description: string,
    language: string,
    controller?: AbortController
  ): Promise<IAssignmentAttachment> {
    const formData = new FormData()
    formData.append('attachmentType', attachmentType)
    formData.append('description', description)
    formData.append('language', language)
    formData.append('file', file)

    return this.api.post(`files/attachment`, formData, controller ? { signal: controller.signal } : undefined, {
      'Content-Type': 'multipart/form-data',
    })
  }

  /**
   * Download assignment attachment file.
   *
   * @param id - Attachment ID.
   * @returns Attachment data as a Blob object.
   */
  public async downloadAttachment(id: number, controller?: AbortController): Promise<Blob> {
    return this.api.get(
      `files/attachment/${id}`,
      controller
        ? { responseType: 'blob', signal: controller.signal }
        : {
            responseType: 'blob',
          }
    )
  }

  /**
   * Download public attachment file.
   *
   * @param id - Public attachment ID.
   * @returns Attachment data as a Blob object.
   */
  public async downloadPublicAttachment(id: number, controller?: AbortController): Promise<Blob> {
    return this.api.get(
      `files/attachment/public/${id}`,
      controller
        ? { responseType: 'blob', signal: controller.signal }
        : {
            responseType: 'blob',
          }
    )
  }

  /**
   * Upload proposal attachment file.
   *
   * @param file - File data.
   * @param attachmentType - Attachment type.
   * @param description - Attachment description.
   * @param language - Attachment language.
   * @returns Attachment data.
   */
  public async createProposalAttachment(
    file: File,
    attachmentType: string,
    description: string,
    language: string,
    controller?: AbortController
  ): Promise<IProposalAttachment> {
    const formData = new FormData()
    formData.append('attachmentType', attachmentType)
    formData.append('description', description)
    formData.append('language', language)
    formData.append('file', file)

    return this.api.post(`files/attachment`, formData, controller ? { signal: controller.signal } : undefined, {
      'Content-Type': 'multipart/form-data',
    })
  }

  /**
   * Download proposal attachment file.
   *
   * @param id - Attachment ID.
   * @returns Attachment data as a Blob object.
   */
  public async downloadProposalAttachment(id: number, controller?: AbortController): Promise<Blob> {
    return this.api.get(
      `files/proposal/attachment/${id}`,
      controller
        ? { responseType: 'blob', signal: controller.signal }
        : {
            responseType: 'blob',
          }
    )
  }

  /**
   * Download public file.
   *
   * @param publicId - Public ID of the file.
   * @returns File data as a Blob object.
   */
  public async downloadFile(publicId: string, controller?: AbortController): Promise<Blob> {
    return this.api.get(
      `files/${publicId}`,
      controller
        ? { responseType: 'blob', signal: controller.signal }
        : {
            responseType: 'blob',
          }
    )
  }

  /**
   * Download public profile picture.
   *
   * @param publicId - Public ID of the profile picture.
   * @returns File data as a string.
   */
  public async downloadProfilePublicId(publicId: string, controller?: AbortController): Promise<string> {
    return this.api.get(`files/profile/publicId/${publicId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Delete uploaded file.
   *
   * @param id - Upload id.
   * @returns 200 status code.
   */
  public async deleteFile(id: IUpload['id'], constroller?: AbortController): Promise<void> {
    return this.api.delete(`files/upload/${id}`, constroller ? { signal: constroller.signal } : undefined)
  }
}

export const filesAPI = new FilesAPI()
