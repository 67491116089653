import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer'
import colors from 'constants/colors'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { chooseDBTranslation } from 'utils/translations'
import About from './About'
import DataLayout from './DataLayout'
import defaultImg from './default.jpg'
import Language from './Language'
import black from './Raleway-Black.ttf'
import bold from './Raleway-Bold.ttf'
import regular from './Raleway-Regular.ttf'
import Skill from './Skill'
import { useStyles } from './styles'
import { ICVData, ILayout, IOrganizationColors } from 'types/layoutInterfaces'
import { CountriesData, LanguagesData } from 'api/UtilsAPI'

interface IPDFrenderProps {
  data: ICVData
  layout: ILayout
  countries: CountriesData
  languages: LanguagesData
  profileImage?: string
  orgLogo?: string
  orgColors: IOrganizationColors
  showContactInfo?: boolean
  hideLastUsed: boolean
}

Font.register({
  family: 'Raleway',
  fonts: [
    { src: regular, format: 'truetype', fontWeight: 500, ellipsis: '...' },
    { src: bold, format: 'truetype', fontWeight: 700 },
    { src: black, format: 'truetype', fontWeight: 900 },
  ],
})
Font.registerHyphenationCallback(word => [word])

Promise.all([
  Font.load({ fontFamily: 'Raleway', fontWeight: 500 }),
  Font.load({ fontFamily: 'Raleway', fontWeight: 700 }),
  Font.load({ fontFamily: 'Raleway', fontWeight: 900 }),
])

/**
 * Renders a PDF document based on the provided data and layout.
 *
 * @param {object} data - The data to be rendered in the PDF.
 * @param {object} layout - The layout configuration for the PDF.
 * @param {object} countries - The countries data used for rendering.
 * @param {object} languages - The languages data used for rendering.
 * @param {string} profileImage - The URL of the profile image to be rendered.
 * @param {string} orgLogo - The URL of the organization logo to be rendered.
 * @param {object} orgColors - The colors configuration for the organization.
 * @param {boolean} showContactInfo - Flag to indicate whether to show contact information.
 * @param {boolean} hideLastUsed - Flag to indicate whether to hide the last used information.
 * @return {JSX.Element} The rendered PDF document.
 * @notExported
 */
const PDFrender: React.FC<IPDFrenderProps> = ({
  data,
  layout,
  countries,
  languages,
  profileImage,
  orgLogo,
  orgColors,
  showContactInfo = false,
  hideLastUsed,
}) => {
  const { i18n } = useTranslation()
  const styles = useStyles()
  const locale = i18n.language
  const options = { year: 'numeric', month: 'short', day: 'numeric' } as const

  if (!data || !layout || !countries || !languages) {
    return (
      <Document>
        <Page></Page>
      </Document>
    )
  }

  return (
    <Document>
      <Page style={styles.page} size="A4">
        <View
          fixed
          render={({ pageNumber }) => {
            if (pageNumber > 1) {
              return <View style={{ marginTop: '30pt' }}></View>
            }
          }}
        />
        <View
          fixed
          render={({ pageNumber }) => {
            if (pageNumber > 1) {
              return (
                <View style={styles.pageNumber}>
                  <Text style={styles.number}>{pageNumber}</Text>
                </View>
              )
            }
          }}
        />
        <View>
          <View
            style={
              layout.id !== 0 && layout.hideProfileImage === true && layout.showContactInfo === false
                ? styles.fullContainerTop
                : styles.containerTop
            }
          >
            <View
              style={[
                layout.id !== 0 && layout.hideProfileImage === true ? styles.fullWithTop : styles.colorTop,
                { backgroundColor: orgColors.color },
              ]}
            >
              <Text style={styles.date}>{new Date().toLocaleDateString(locale, options)}</Text>
            </View>
            <View
              style={
                layout.id !== 0 && layout.hideProfileImage === true
                  ? styles.fullPersonalDetails
                  : styles.personalDetails
              }
            >
              <Text
                style={[
                  styles.name,
                  {
                    color: orgColors.accentColor,
                  },
                ]}
              >
                {layout && layout.hideName
                  ? chooseDBTranslation(i18n, data.about).primaryRole
                  : `${data.about.firstName ?? ''} ${data.about.lastName ?? ''}`}
              </Text>
              {layout && layout.hideName === false && (
                <Text style={styles.itemHeader}>{chooseDBTranslation(i18n, data.about).primaryRole}</Text>
              )}
              {showContactInfo && (
                <View style={{ marginTop: '5pt' }}>
                  <Text style={styles.text}>{data.about.telephone ?? ''}</Text>
                  <Text style={styles.text}>{data.about.email ?? ''}</Text>
                  <View style={{ marginTop: '5pt' }}></View>
                  <Text style={[styles.text, { color: colors.gray }]}>{data.about.streetAddress ?? ''}</Text>
                </View>
              )}
              <Text style={[styles.text, { color: colors.gray }]}>
                {showContactInfo && `${data.about.postalCode ?? ''} `}
                {data.about.city && data.about.country
                  ? `${data.about.city}, ${countries[data.about.country?.toUpperCase()]}`
                  : ''}
              </Text>
            </View>
            <Image style={styles.orgImage} src={orgLogo ? orgLogo : defaultImg} cache={true} />
            {layout.id !== 0 && layout.hideProfileImage === false && (
              <Image style={styles.profileImage} src={profileImage ? profileImage : defaultImg} cache={true} />
            )}
            {layout.id === 0 && (
              <Image style={styles.profileImage} src={profileImage ? profileImage : defaultImg} cache={true} />
            )}
          </View>

          {chooseDBTranslation(i18n, data.about).about && chooseDBTranslation(i18n, data.about).about.length > 0 ? (
            <About
              description={chooseDBTranslation(i18n, data.about).about}
              layout={layout}
              headingColor={orgColors.accentColor}
            />
          ) : null}
          {data.skills.length > 0 && (
            <Skill
              items={data.skills}
              headingColor={orgColors.accentColor}
              type="skills"
              isHidden={false}
              hideLastUsed={hideLastUsed}
            />
          )}
          {data.industries.length > 0 && (
            <Skill
              items={data.industries}
              headingColor={orgColors.accentColor}
              type="industries"
              isHidden={layout.id === 0 ? true : false}
              hideLastUsed={hideLastUsed}
            />
          )}
          {data.roles.length > 0 && (
            <Skill
              items={data.roles}
              headingColor={orgColors.accentColor}
              type="roles"
              isHidden={layout.id === 0 ? true : false}
              hideLastUsed={hideLastUsed}
            />
          )}
          {data.employments.length > 0 && (
            <DataLayout data={data} type={'employments'} headingColor={orgColors.accentColor} />
          )}
          {data.projects.length > 0 && (
            <DataLayout data={data} type={'projects'} headingColor={orgColors.accentColor} />
          )}
          {data.degrees.length > 0 && <DataLayout data={data} type={'degrees'} headingColor={orgColors.accentColor} />}
          {data.courses.length > 0 && <DataLayout data={data} type={'courses'} headingColor={orgColors.accentColor} />}
          {data.certificates.length > 0 && (
            <DataLayout data={data} type={'certificates'} headingColor={orgColors.accentColor} layout={layout} />
          )}
          {data.references.length > 0 && (
            <DataLayout data={data} type={'references'} headingColor={orgColors.accentColor} />
          )}
          {data.languages.length > 0 && (
            <Language items={data.languages} headingColor={orgColors.accentColor} languages={languages} />
          )}
          {data.urls.length > 0 && <DataLayout data={data} type={'urls'} headingColor={orgColors.accentColor} />}
        </View>
      </Page>
    </Document>
  )
}

export default PDFrender
