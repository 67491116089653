import { Button, CardHeader, FormControlLabel, Grid, Switch, Typography, useMediaQuery, useTheme } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import ReactPDF, { PDFViewer, usePDF } from '@react-pdf/renderer'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { chooseDBTranslation } from 'utils/translations'
import DOCXrender, { CVData } from './DOCXrender'
import PDFrender from './PDFrender'
import { usePreviewData } from './PrintPreviewProvider'
import QuickPreview from './QuickPreview'
import { CountriesData, LanguagesData } from 'api/UtilsAPI'
import { IOrganizationColors } from 'types/layoutInterfaces'

interface IPreviewProps {
  /* The list of countries. */
  countries: CountriesData
  /* The list of languages. */
  languages: LanguagesData
  /* The profile image to display. */
  profileImage?: string
  /* The organization logo to display. */
  orgLogo?: string
  /* The colors of the organization. */
  orgColors: IOrganizationColors
  /* Indicates if the data is still loading. */
  loading?: boolean
}

/**
 * Renders a preview of the profile with the given data.
 *
 * @param {Object} profileImage - The profile image to display.
 * @param {Object} orgLogo - The organization logo to display.
 * @param {Object} orgColors - The colors of the organization.
 * @param {boolean} loading - Indicates if the data is still loading.
 * @param {Array} countries - The list of countries.
 * @param {Array} languages - The list of languages.
 * @return {JSX.Element} The rendered preview.
 * @notExported
 */
const Preview: React.FC<IPreviewProps> = ({ countries, languages, profileImage, orgLogo, orgColors, loading }) => {
  const { t, i18n } = useTranslation()
  const { visibleLayout, visibleData, data } = usePreviewData()
  const [showPDF, setShowPDF] = useState<boolean>(false)
  const [document, setDocument] =
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    useState<React.ReactElement<ReactPDF.DocumentProps, string | React.JSXElementConstructor<any>>>()
  const [pdf, update] = usePDF()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'))

  useEffect(() => {
    if (visibleData && visibleLayout && countries && languages) {
      const data = (
        <PDFrender
          data={visibleData}
          layout={visibleLayout}
          countries={countries}
          languages={languages}
          profileImage={profileImage}
          orgLogo={orgLogo}
          orgColors={orgColors}
          showContactInfo={visibleLayout?.showContactInfo}
          hideLastUsed={visibleLayout?.hideLastUsed}
        />
      )
      if (data) {
        update(data)
        setDocument(data)
      }
    }
  }, [visibleData, visibleLayout, orgColors, profileImage, orgLogo, countries, languages, showPDF])

  const pdfViewer = useMemo(() => {
    if (!pdf.loading && !pdf.error && pdf.url && pdf.blob && document) {
      return (
        <PDFViewer style={{ height: 'calc(100vh - 190px)', width: '100%' }} showToolbar={false}>
          {document}
        </PDFViewer>
      )
    }
    return null
  }, [document, showPDF, pdf.blob, pdf.error])

  // Generating the pdf after user has requested it
  const generatePdfDocument = () => {
    const filename = `${
      chooseDBTranslation(i18n, data?.about.Organization)?.name ?? 'freelancer'
    }_CV_${data?.about.firstName.trim()}_${data?.about.lastName.trim()}_${new Date(
      Date.now()
    ).toLocaleDateString()}.pdf`

    if (pdf.loading && !profileImage && !countries && !languages) return <Button disabled>{t('loading')}</Button>

    if (pdf.error) return <div>Something went wrong: {pdf.error}</div>

    if (typeof pdf.url === 'string') {
      return (
        <Button href={pdf.url} download={filename}>
          {' '}
          {t('print.download')}{' '}
        </Button>
      )
    }
  }

  const getPreviewVersionButtons = () => {
    return (
      <FormControlLabel
        control={<Switch checked={showPDF} onChange={() => setShowPDF(!showPDF)} name="pdfPreview" />}
        label={t('print.preview.pdf')}
      />
    )
  }

  const getActionButtons = () => {
    // TODO: check Docx details and change to same non-star display
    return data && visibleLayout ? (
      <>
        {!mobileView && getPreviewVersionButtons()}
        {generatePdfDocument()}
        <DOCXrender
          data={data as CVData}
          layout={visibleLayout}
          countries={countries}
          profileImage={profileImage}
          orgLogo={orgLogo}
          orgColors={orgColors}
          languages={languages}
        />
      </>
    ) : null
  }

  return (
    <Grid>
      <CardHeader
        title={
          <span>
            {t('print.preview.title')} {loading ? <CircularProgress size={25} /> : null}
          </span>
        }
        action={getActionButtons()}
      />
      {!mobileView ? (
        <>
          {!showPDF && countries && languages ? (
            <QuickPreview
              countries={countries}
              languages={languages}
              profileImage={profileImage}
              orgLogo={orgLogo}
              orgColors={orgColors}
            />
          ) : (
            <span style={{ width: '45vw' }}>{pdfViewer}</span>
          )}
        </>
      ) : (
        <>
          <Typography>{t('noMobileView')}</Typography>
        </>
      )}
    </Grid>
  )
}

export default Preview
