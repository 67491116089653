import { Box } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { networkAPI } from 'api/NetworkAPI'
import ErrorOverlay from 'Components/General/ErrorOverlay'
import CaleoInputLabel from 'Components/reusable/CaleoCustomComponents/CaleoInputLabel'
import { useIsComponentMounted } from 'hooks/util'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IError } from 'types/error'
import { IAssignmentVisibility, INetwork } from 'types/networkInterfaces'
import { chooseDBTranslation } from 'utils/translations'
import { fuseFiltering } from 'utils/utils'
import { InputField } from '../InputField'

/** @notExported */
interface IAssignmentVisibilityPickerProps {
  /** The current value. */
  value: IAssignmentVisibility[]
  /** Action to do when the value changes. */
  onChange: (newValue: IAssignmentVisibility[] | null) => void
  /** The label text. */
  label?: string
  /** Whether the field is required. */
  required?: boolean
  /** Whether the field is disabled. */
  disabled?: boolean
  /** Whether the field is for sharing to network. */
  share?: boolean
  /** Is the label inset. */
  insetLabel?: boolean
}

/**
 * Assignment visibility picker component.
 *
 * @returns The assignment visibility picker component.
 * @notExported
 */
const AssignmentVisibilityPicker: React.FC<IAssignmentVisibilityPickerProps> = ({
  value,
  onChange,
  label = 'NetworkVisibility',
  required = false,
  disabled = false,
  share = false,
  insetLabel,
  ...rest
}) => {
  const isComponentMounted = useIsComponentMounted()
  const { i18n } = useTranslation()

  const [items, setItems] = useState<IAssignmentVisibility[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [backendError, setBackendError] = useState<IError>()

  useEffect(() => {
    ;(async () => {
      const controller = new AbortController()

      try {
        if (items.length > 0) return
        setLoading(true)
        let networks: INetwork[] = []
        if (share) {
          networks = await networkAPI.getBrokerAccessNetworks(controller)
        } else {
          networks = await networkAPI.getNetworksShareList(controller)
        }
        if (isComponentMounted.current) {
          setItems(
            networks.map((network: INetwork) => {
              return {
                id: null,
                assignmentId: null,
                networkId: network.id,
                Network: network,
                createdAt: new Date(),
                updatedAt: new Date(),
                acceptedAt: null,
                status: false,
                notify: false,
              }
            })
          )
          setLoading(false)
        }
      } catch (error) {
        setBackendError(error as IError)
      }

      return () => {
        controller.abort()
      }
    })()
  }, [])

  const getName = (item: IAssignmentVisibility) => {
    return chooseDBTranslation(i18n, item.Network).name
  }

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  return (
    <>
      {!insetLabel && <CaleoInputLabel label={label} required={required} />}
      <Autocomplete
        loading={loading}
        disabled={disabled}
        value={value}
        onChange={(_event, newValue) => {
          if (typeof newValue !== 'string') {
            onChange(newValue)
          }
        }}
        filterOptions={(options, { inputValue }) => fuseFiltering(options, inputValue, ['Network.translations.name'])}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={items}
        getOptionLabel={option => {
          return getName(option)
        }}
        isOptionEqualToValue={(option, value) => {
          return option.networkId === value.networkId
        }}
        renderOption={(props, option) => {
          return (
            <Box component="li" {...props} key={option.networkId}>
              {getName(option)}
            </Box>
          )
        }}
        renderInput={params => (
          <InputField
            {...params}
            insetLabel={insetLabel}
            fullWidth
            margin="dense"
            size="small"
            variant="outlined"
            label={insetLabel ? label : undefined}
            InputLabelProps={{ shrink: insetLabel ? true : undefined }}
            required={required}
          />
        )}
        fullWidth
        multiple
        filterSelectedOptions
        {...rest}
      />
    </>
  )
}

export default AssignmentVisibilityPicker
