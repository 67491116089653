import { chooseDBTranslation } from 'utils/translations'
import {
  Table,
  TableRow,
  TableCell,
  Paragraph,
  WidthType,
  BorderStyle,
  HeightRule,
  ShadingType,
  TextRun,
  AlignmentType,
  ImageRun,
} from 'docx'
import { CVData } from './index'
import colors from 'constants/colors'

/**
 * Generates a Table object based on the provided data and layout options.
 *
 * @param {string} i18n - The i18n object for localization.
 * @param {CVData} data - The data object containing CV information.
 * @param {Array} countries - The array of available countries.
 * @param {string} profileImage - The profile image data.
 * @param {string} orgLogo - The organization logo data.
 * @param {string} orgColors - The organization colors object.
 * @param {string} layout - The layout options for the table.
 * @param {string} defaultImg - The default image data.
 * @return {Table} The generated Table object.
 * @notExported
 */
const top = (i18n, data: CVData, countries, profileImage, orgLogo, orgColors, layout, defaultImg): Table => {
  const shading = {
    color: orgColors && orgColors.color ? orgColors.color : colors.secondary,
    type: ShadingType.SOLID,
    fill: colors.secondaryBackground,
  }

  const borders = {
    top: {
      style: BorderStyle.NONE,
      size: 0,
      color: '#FF0000',
    },
    bottom: {
      style: BorderStyle.NONE,
      size: 0,
      color: '#FF0000',
    },
    left: {
      style: BorderStyle.NONE,
      size: 0,
      color: '#FF0000',
    },
    right: {
      style: BorderStyle.NONE,
      size: 0,
      color: '#FF0000',
    },
  }

  let image: ImageRun, logo: ImageRun

  if (profileImage) {
    image = new ImageRun({
      type: 'png',
      data: profileImage,
      transformation: {
        width: 200,
        height: 200,
      },
      floating: {
        horizontalPosition: {
          offset: 0,
        },
        verticalPosition: {
          offset: 0,
        },
      },
    })
  } else {
    image = new ImageRun({
      type: 'png',
      data: defaultImg,
      transformation: {
        width: 200,
        height: 200,
      },
      floating: {
        horizontalPosition: {
          offset: 0,
        },
        verticalPosition: {
          offset: 0,
        },
      },
    })
  }

  if (orgLogo) {
    logo = new ImageRun({
      type: 'png',
      data: orgLogo,
      transformation: {
        width: 100,
        height: 100,
      },
    })
  } else {
    logo = new ImageRun({
      type: 'png',
      data: defaultImg,
      transformation: {
        width: 100,
        height: 100,
      },
    })
  }

  const leftMargin = !layout.hideProfileImage ? 800 : 0

  return new Table({
    width: {
      size: 9638,
      type: WidthType.DXA,
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            rowSpan: !layout.hideProfileImage ? 6 : 10,
            children: !layout.hideProfileImage ? [new Paragraph({ children: [image] })] : [],
            width: {
              size: !layout.hideProfileImage ? 3300 : 0,
              type: WidthType.DXA,
            },
            borders,
          }),
          new TableCell({
            children: [],
            borders,
            shading,
            width: {
              size: 1700,
              type: WidthType.DXA,
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text:
                      new Date().toLocaleDateString(i18n.language, {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      }) + ' ',
                    font: {
                      name: 'Raleway',
                    },
                    size: 15,
                    color: colors.secondaryBackground,
                  }),
                ],
                spacing: {
                  before: 100,
                  after: 200,
                },
                alignment: AlignmentType.END,
              }),
            ],
            columnSpan: 3,
            borders,
            shading,
            margins: {
              right: 200,
            },
            width: {
              size: 4638,
              type: WidthType.DXA,
            },
          }),
        ],
        height: {
          value: 800,
          rule: HeightRule.ATLEAST,
        },
      }),
      new TableRow({
        height: {
          value: 100,
          rule: HeightRule.ATLEAST,
        },
        children: [
          new TableCell({
            borders,
            margins: {
              bottom: 300,
              top: 300,
              left: leftMargin,
            },
            width: {
              size: 3200,
              type: WidthType.DXA,
            },
            children: [
              // name
              new Paragraph({
                children: [
                  new TextRun({
                    text: !layout.hideName
                      ? `${data.about.firstName} ${data.about.lastName}`
                      : chooseDBTranslation(i18n, data.about).primaryRole,
                    bold: true,
                    color:
                      data.about.Organization && data.about.Organization.accentColor
                        ? data.about.Organization.accentColor.substring(1)
                        : colors.secondary,
                    size: 30,
                    font: {
                      name: 'Raleway',
                    },
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            borders,
            children: [
              new Paragraph({
                alignment: AlignmentType.RIGHT,
                children: [logo],
              }),
            ],
            rowSpan: 6,
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            borders,
            margins: {
              bottom: 50,
              left: leftMargin,
            },
            children: !layout.hideName
              ? [
                  // title
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: chooseDBTranslation(i18n, data.about).primaryRole,
                        bold: true,
                        font: {
                          name: 'Raleway',
                        },
                      }),
                    ],
                  }),
                ]
              : [],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            borders,
            children: layout.showContactInfo
              ? [
                  // postalCode city, country
                  new Paragraph({
                    children: data.about.streetAddress
                      ? [
                          new TextRun({
                            text: data.about.streetAddress,
                            font: {
                              name: 'Raleway',
                            },
                            color: colors.gray,
                          }),
                        ]
                      : [],
                  }),
                  new Paragraph({
                    children:
                      data.about.postalCode && data.about.city && data.about.country
                        ? [
                            new TextRun({
                              text: `${data.about.postalCode} ${data.about.city}, ${
                                countries[data.about.country.toUpperCase()]
                              }`,
                              font: {
                                name: 'Raleway',
                              },
                              color: colors.gray,
                            }),
                          ]
                        : [],
                  }),
                ]
              : [
                  new Paragraph({
                    children:
                      data.about.city && data.about.country
                        ? [
                            new TextRun({
                              text: `${data.about.city}, ${countries[data.about.country.toUpperCase()]}`,
                              font: {
                                name: 'Raleway',
                              },
                              color: colors.gray,
                            }),
                          ]
                        : [],
                  }),
                ],
            margins: {
              bottom: 200,
              left: leftMargin,
            },
          }),
        ],
      }),
      new TableRow({
        children: layout.showContactInfo
          ? [
              new TableCell({
                borders,
                margins: {
                  top: 50,
                  left: leftMargin,
                },
                children: [
                  // telephone
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: data.about.telephone,
                        font: {
                          name: 'Raleway',
                        },
                      }),
                    ],
                  }),
                ],
              }),
            ]
          : [],
      }),
      new TableRow({
        children: layout.showContactInfo
          ? [
              new TableCell({
                borders,
                margins: {
                  bottom: 50,
                  left: leftMargin,
                },
                children: [
                  // email
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: data.about.email,
                        font: {
                          name: 'Raleway',
                        },
                      }),
                    ],
                  }),
                ],
              }),
            ]
          : [],
      }),
    ],
  })
}

export default top
