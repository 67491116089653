import {
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import ArrowIcon from '@mui/icons-material/ArrowForwardIos'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import PollIcon from '@mui/icons-material/Poll'
import SearchIcon from '@mui/icons-material/Search'
import BusinessIcon from '@mui/icons-material/Business'
import AssignmentIcon from '@mui/icons-material/Assignment'
import SettingsIcon from '@mui/icons-material/Settings'
import PictureAsPdf from '@mui/icons-material/PictureAsPdf'
import TeamIcon from '@mui/icons-material/People'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded'
import HelpIcon from '@mui/icons-material/Help'
import ChevronIcon from '@mui/icons-material/DoubleArrowSharp'
import DiamondIcon from '@mui/icons-material/Diamond'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import ChatIcon from '@mui/icons-material/Chat'
import LogoDevIcon from '@mui/icons-material/LogoDev'
import WorkIcon from '@mui/icons-material/Work'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import logoWhite from 'assets/images/caleoLogo.svg'
import { useUser } from 'hooks'
import { useMenu } from './menu'
import caleoFlame from 'assets/images/Caleo-flame.png'
import StatisticsIcon from '@mui/icons-material/PieChart'
import { INetwork } from 'types/networkInterfaces'
import { networkAPI } from 'api/NetworkAPI'
import ErrorOverlay from '../ErrorOverlay'
import { IError } from 'types/error'
import MenuIcon from '@mui/icons-material/Menu'
import { ToolbarItems } from '../Toolbar/ToolbarItems'

/**
 * Side navigation menu.
 *
 * @returns Navigation menu component.
 * @notExported
 */
const NavigationMenu: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const { groups, networkAccess, user, ready, features } = useUser()
  const caleo = 'Caleo | '
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))
  const { showMenu, setMenu } = useMenu()
  const [open, setOpen] = useState<boolean>(false)
  const [networks, setNetworks] = useState<INetwork[]>()
  const [backendError, setBackendError] = useState<IError>()

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (user) {
        try {
          const results = await networkAPI.getNetworkBenefits(controller)
          setNetworks(results)
        } catch (error) {
          setBackendError(error as IError)
        }
      }
    })()

    return () => {
      controller.abort()
    }
  }, [user])

  document.title = caleo + t(`navigation.${location.pathname.split('/')[1]}`)

  if (backendError && backendError.name !== 'CanceledError' && backendError.name !== 'AbortError') {
    return <ErrorOverlay error={backendError} setOpen={setBackendError} />
  }

  let hideNavMenu = false
  if (
    location.pathname &&
    (location.pathname.includes('/reset-password') ||
      location.pathname.includes('/forgot-password') ||
      location.pathname.includes('/change-password') ||
      location.pathname.includes('/login') ||
      location.pathname.includes('/activate') ||
      location.pathname.includes('/confirm') ||
      location.pathname.includes('/register') ||
      location.pathname.includes('/assignments/public') ||
      (location.pathname.includes('/salespool') && !user) ||
      location.pathname.includes('/terms') ||
      location.pathname.includes('/privacy-policy'))
  ) {
    hideNavMenu = true
  }

  const person = ready && user && user.Person !== null

  const listItem = (id: string, action: string, icon: JSX.Element, text: string, currentPage: boolean) => {
    return (
      <ListItem
        id={id}
        sx={{
          paddingLeft: '23px',
          paddingRight: '0px',
          [theme.breakpoints.down('sm')]: {
            paddingLeft: '13px',
          },
          cursor: 'pointer',
        }}
        onClick={() => {
          navigate(action)
        }}
      >
        {showMenu ? (
          <>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text} />
            {currentPage && (
              <ListItemIcon>
                <ArrowIcon />
              </ListItemIcon>
            )}
          </>
        ) : (
          <Tooltip title={String(text)} placement="right" arrow>
            <ListItemIcon>{icon}</ListItemIcon>
          </Tooltip>
        )}
      </ListItem>
    )
  }

  const handleLogoClick = () => {
    groups.includes('sales') ? navigate('/employees') : navigate('/profile')
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <>
      {!hideNavMenu && (
        <>
          <ToolbarItems>
            <IconButton
              color="primary"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                mr: 2,
                display: { sx: 'block', md: 'none' },
                [theme.breakpoints.up('sm')]: {
                  visibility: 'hidden',
                },
              }}
            >
              <MenuIcon />
            </IconButton>
          </ToolbarItems>
          <Drawer
            variant={!mobileView || (mobileView && open) ? 'permanent' : 'persistent'}
            open={showMenu}
            sx={{
              ...{ width: '240px', flexShrink: 0, zIndex: 1299, whiteSpace: 'nowrap' },
              ...(showMenu
                ? {
                    backgroundColor: theme.palette.primary.main,
                    width: '240px',
                    transition: theme.transitions.create('width', {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.enteringScreen,
                    }),
                  }
                : {
                    backgroundColor: theme.palette.primary.main,
                    transition: theme.transitions.create('width', {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.leavingScreen,
                    }),
                    overflowX: 'hidden',
                    width: theme.spacing(7) + 1,
                    [theme.breakpoints.up('sm')]: {
                      width: theme.spacing(9) + 1,
                    },
                  }),
            }}
            PaperProps={{
              sx: showMenu
                ? {
                    backgroundColor: theme.palette.primary.main,
                    width: '240px',
                    transition: theme.transitions.create('width', {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.enteringScreen,
                    }),
                  }
                : {
                    backgroundColor: theme.palette.primary.main,
                    transition: theme.transitions.create('width', {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.leavingScreen,
                    }),
                    overflowX: 'hidden',
                    width: theme.spacing(7) + 1,
                    [theme.breakpoints.up('sm')]: {
                      width: theme.spacing(9) + 1,
                    },
                  },
            }}
          >
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: theme.spacing(0, 1),
                ...theme.mixins.toolbar,
                justifyContent: 'flex-end',
              }}
            >
              {showMenu ? (
                <>
                  <img
                    src={logoWhite}
                    alt="logo"
                    style={{ width: '74%', padding: '15px', cursor: 'pointer' }}
                    onClick={handleLogoClick}
                  />
                  <IconButton
                    sx={{
                      color: theme.palette.secondary.main,
                      '&:hover': {
                        backgroundColor: 'unset !important',
                      },
                    }}
                    aria-label="close drawer"
                    onClick={() => setMenu(false)}
                    size="large"
                  >
                    <ChevronIcon style={{ transform: 'rotate(-180deg)' }} />
                  </IconButton>
                </>
              ) : open ? (
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <Grid item>
                    <IconButton
                      aria-label="close drawer"
                      color="secondary"
                      onClick={handleDrawerClose}
                      edge="start"
                      sx={{ ml: '2px' }}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ) : (
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <Grid item>
                    <img
                      src={caleoFlame}
                      alt="logo"
                      style={{
                        width: '44px',
                        marginTop: '10px',
                        marginLeft: '6px',
                        marginRight: '6px',
                        [theme.breakpoints.down('sm')]: {
                          width: '26px',
                        },
                      }}
                      onClick={handleLogoClick}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      sx={{
                        color: theme.palette.secondary.main,
                        '&:hover': {
                          backgroundColor: 'unset !important',
                        },
                      }}
                      aria-label="open drawer"
                      onClick={() => setMenu(true)}
                      size="large"
                    >
                      <ChevronIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Divider />
            <List component="nav">
              {features &&
                features.includes('profile') &&
                listItem(
                  'nav-user',
                  '/profile',
                  <PersonOutlineIcon
                    sx={{
                      backgroundColor: location.pathname.includes('/profile')
                        ? theme.palette.secondary.main
                        : 'inherit',
                      borderRadius: '3px',
                    }}
                  />,
                  t('navigation.profile'),
                  location.pathname.includes('/profile')
                )}
              {features &&
                groups &&
                features.includes('profile') &&
                !(groups.includes('sales') || groups.includes('companyAdmin') || groups.includes('admin')) &&
                person &&
                listItem(
                  'nav-print',
                  '/print',
                  <PictureAsPdf
                    sx={{
                      backgroundColor: location.pathname.includes('/print') ? theme.palette.secondary.main : 'inherit',
                      borderRadius: '3px',
                    }}
                  />,
                  t('navigation.print'),
                  location.pathname.includes('/print')
                )}
              {features &&
                features.includes('allocations') &&
                person &&
                listItem(
                  'nav-allocation',
                  '/allocation',
                  <PollIcon
                    sx={{
                      backgroundColor: location.pathname.includes('/allocation')
                        ? theme.palette.secondary.main
                        : 'inherit',
                      borderRadius: '3px',
                    }}
                  />,
                  t('navigation.allocation'),
                  location.pathname.includes('/allocation')
                )}
              {features &&
                groups &&
                features.includes('search') &&
                (groups.includes('admin') || groups.includes('sales')) &&
                person &&
                listItem(
                  'nav-search',
                  '/employees',
                  <SearchIcon
                    sx={{
                      backgroundColor:
                        location.pathname.includes('/employees') &&
                        !location.pathname.includes('/networks') &&
                        !location.pathname.includes('/admin')
                          ? theme.palette.secondary.main
                          : 'inherit',
                      borderRadius: '3px',
                    }}
                  />,
                  t('navigation.employees'),
                  location.pathname.includes('/employees') &&
                    !location.pathname.includes('/networks') &&
                    !location.pathname.includes('/admin')
                )}
              {features &&
                groups &&
                features.includes('assignments') &&
                (groups.includes('sales') || groups.includes('freelancer')) &&
                listItem(
                  'nav-assignments',
                  '/assignments',
                  <AssignmentIcon
                    sx={{
                      backgroundColor: location.pathname.includes('/assignments')
                        ? theme.palette.secondary.main
                        : 'inherit',
                      borderRadius: '3px',
                    }}
                  />,
                  t('navigation.assignments'),
                  location.pathname.includes('/assignments')
                )}
              {features &&
                groups &&
                features.includes('workOrders') &&
                (groups.includes('sales') || groups.includes('freelancer')) &&
                listItem(
                  'nav-workorders',
                  '/workorders',
                  <WorkIcon
                    sx={{
                      backgroundColor: location.pathname.includes('/workorders')
                        ? theme.palette.secondary.main
                        : 'inherit',
                      borderRadius: '3px',
                    }}
                  />,
                  t('navigation.workorders'),
                  location.pathname.includes('/workorders')
                )}
              {features &&
                features.includes('teams') &&
                listItem(
                  'nav-teams',
                  '/teams',
                  <TeamIcon
                    sx={{
                      backgroundColor: location.pathname.includes('/teams') ? theme.palette.secondary.main : 'inherit',
                      borderRadius: '3px',
                    }}
                  />,
                  t('navigation.teams'),
                  location.pathname.includes('/teams')
                )}
              {features &&
                groups &&
                features.includes('companyAdmin') &&
                groups.includes('companyAdmin') &&
                listItem(
                  'nav-company-admin',
                  '/company-admin',
                  <BusinessIcon
                    sx={{
                      backgroundColor: location.pathname.includes('/company-admin')
                        ? theme.palette.secondary.main
                        : 'inherit',
                      borderRadius: '3px',
                    }}
                  />,
                  t('navigation.company-admin'),
                  location.pathname.includes('/company-admin')
                )}
              {features &&
                groups &&
                features.includes('reports') &&
                (groups.includes('companyAdmin') || groups.includes('admin') || groups.includes('teamLeader')) &&
                listItem(
                  'nav-statistics',
                  '/statistics',
                  <StatisticsIcon
                    sx={{
                      backgroundColor: location.pathname.includes('/statistics')
                        ? theme.palette.secondary.main
                        : 'inherit',
                      borderRadius: '3px',
                    }}
                  />,
                  t('navigation.statistics'),
                  location.pathname.includes('/statistics')
                )}
              {groups &&
                (groups.includes('admin') || groups.includes('inputDataManager')) &&
                listItem(
                  'nav-admin',
                  '/admin',
                  <SettingsIcon
                    sx={{
                      backgroundColor: location.pathname.includes('/admin') ? theme.palette.secondary.main : 'inherit',
                      borderRadius: '3px',
                    }}
                  />,
                  t('navigation.admin'),
                  location.pathname.includes('/admin')
                )}
              {features &&
                groups &&
                features.includes('networks') &&
                networkAccess &&
                (groups.includes('admin') || groups.includes('sales')) &&
                listItem(
                  'nav-networks',
                  '/networks',
                  <AccountTreeIcon
                    sx={{
                      backgroundColor:
                        location.pathname.includes('/networks') && !location.pathname.includes('/benefits')
                          ? theme.palette.secondary.main
                          : 'inherit',
                      borderRadius: '3px',
                    }}
                  />,
                  t('navigation.networks'),
                  location.pathname.includes('/networks') && !location.pathname.includes('/benefits')
                )}
              {features &&
                features.includes('scout') &&
                person &&
                listItem(
                  'nav-scout',
                  '/scout',
                  <ListAltRoundedIcon
                    sx={{
                      backgroundColor: location.pathname.includes('/scout') ? theme.palette.secondary.main : 'inherit',
                      borderRadius: '3px',
                      transform: 'rotate(-180deg)',
                    }}
                  />,
                  t('navigation.scout'),
                  location.pathname.includes('/scout')
                )}
              {features &&
                groups &&
                features.includes('salespool') &&
                groups.includes('sales') &&
                listItem(
                  'nav-salespool',
                  '/salespool',
                  <ShoppingBasketIcon
                    sx={{
                      backgroundColor: location.pathname.includes('/salespool')
                        ? theme.palette.secondary.main
                        : 'inherit',
                      borderRadius: '3px',
                    }}
                  />,
                  t('navigation.salespool'),
                  location.pathname.includes('/salespool')
                )}
              {features &&
                features.includes('chat') &&
                listItem(
                  'nav-chat',
                  '/chat',
                  <ChatIcon
                    sx={{
                      backgroundColor: location.pathname.includes('/chat') ? theme.palette.secondary.main : 'inherit',
                      borderRadius: '3px',
                    }}
                  />,
                  t('navigation.chat'),
                  location.pathname.includes('/chat')
                )}
              {networks &&
                networks?.length > 0 &&
                listItem(
                  'nav-benefits',
                  '/networks/benefits',
                  <DiamondIcon
                    sx={{
                      backgroundColor: location.pathname.includes('/benefits')
                        ? theme.palette.secondary.main
                        : 'inherit',
                      borderRadius: '3px',
                    }}
                  />,
                  t('navigation.benefits'),
                  location.pathname.includes('/benefits')
                )}
              {groups &&
                groups.includes('admin') &&
                listItem(
                  'nav-help',
                  '/help',
                  <HelpIcon
                    sx={{
                      backgroundColor: location.pathname.includes('/help') ? theme.palette.secondary.main : 'inherit',
                      borderRadius: '3px',
                    }}
                  />,
                  t('navigation.help'),
                  location.pathname.includes('/help')
                )}
              {listItem(
                'nav-releases',
                '/releases',
                <LogoDevIcon
                  sx={{
                    backgroundColor: location.pathname.includes('/releases') ? theme.palette.secondary.main : 'inherit',
                    borderRadius: '3px',
                  }}
                />,
                t('navigation.releases'),
                location.pathname.includes('/releases')
              )}
            </List>
          </Drawer>
        </>
      )}
    </>
  )
}

export default NavigationMenu
