import { useUser } from 'hooks'
import React, { useState, useEffect, createContext } from 'react'
import { IChatMember, IChatMessage, IChatRoom } from 'types/chatInterfaces'
import { IError } from 'types/error'
import { IAccount } from 'types/userInterfaces'
import { chatAPI } from 'api/ChatApi'
import { cloneDeep, orderBy } from 'lodash'
import { Encrypt } from 'utils/aes'
import { useTranslation } from 'react-i18next'
import CaleoYellowButton from 'Components/reusable/Buttons/CaleoYellowButton'
import { getAuthorization, socket } from 'api/WebSocket'
import { useLocation, useNavigate } from 'react-router-dom'

export interface ChatState {
  isConnected: boolean
  chatSecret: string
  allowedUsers: IAccount[]
  userRooms: IChatRoom[]
  roomData: IChatRoom | undefined
  userInvites: IChatMember[]
  newMessage: Date | undefined
  loading: boolean
  error: IError | undefined
  fullyRendered: boolean
  connecting: boolean | undefined
  showNotification: boolean
  notificationData?: {
    message: string
    type: 'error' | 'warning' | 'info' | 'success'
    duration?: 'short' | 'long' | 'indefinite'
    action?: React.ReactNode
  }
  closeNotification: () => void
  setFullyRendered: (value: boolean) => void
  setIsConnected: (value: boolean) => void
  setUserRooms: (value: IChatRoom[]) => void
  setRoomData: (value: IChatRoom | undefined) => void
  setUserInvites: (value: IChatMember[]) => void
  setLoading: (value: boolean) => void
  setError: (value: IError | undefined) => void
  connect: (user: IAccount) => Promise<void>
  joinRoom: (name: IChatRoom['name'], noRedirect?: boolean) => Promise<void>
  leaveRoom: (name: IChatRoom['name']) => Promise<void>
  sendMessage: (room: IChatMessage['roomName'], message: IChatMessage['message']) => Promise<void>
  deleteMessage: (messageId: IChatMessage['id']) => Promise<void>
  createRoom: (data: IChatRoom, noRedirect?: boolean) => Promise<void>
  saveRoom: (data: IChatRoom) => Promise<IChatRoom | undefined>
  deleteRoom: (data: IChatRoom) => Promise<void>
  getRoomsInvites: () => Promise<void>
  sendUser: (userId: number) => void
  invite: (room: IChatRoom['name'], accountIds: number[]) => void
  getMoreMessages: (room: IChatRoom['name'], lastMessage: IChatMessage) => void
  sendNotification: (type: string, accountId: number, message?: string) => void
  reconnect: (name?: string) => void
}

export const ChatContext = createContext<ChatState>({} as ChatState)

const { Provider } = ChatContext

/**
 * Provider for handling chat operations in the application.
 *
 * @param children
 * @returns Provider for handling chat operations.
 * @notExported
 */
const ChatProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, features } = useUser()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()

  const [isConnected, setIsConnected] = useState<boolean>(false)
  const [chatSecret, setChatSecret] = useState<string>('')
  const [allowedUsers, setAllowedUsers] = useState<IAccount[]>([])
  const [userRooms, setUserRooms] = useState<IChatRoom[]>([])
  const [roomData, setRoomData] = useState<IChatRoom>()
  const [userInvites, setUserInvites] = useState<IChatMember[]>([])
  const [newMessage, setNewMessage] = useState<Date>()
  const [loading, setLoading] = useState<boolean>(true)
  const [fullyRendered, setFullyRendered] = useState<boolean>(false)
  const [error, setError] = useState<IError>()
  const [connecting, setConnecting] = useState<boolean>()
  const [showNotification, setShowNotification] = useState<boolean>(false)
  const [notificationData, setNotificationData] = useState<{
    message: string
    type: 'error' | 'warning' | 'info' | 'success'
    duration?: 'short' | 'long' | 'indefinite'
    action?: React.ReactNode
  }>()

  /**
   * Function to connect to Socket IO server.
   *
   * @param user - User data.
   * @returns Returns if already connecting.
   * @notExported
   */
  const connect = async (user: IAccount) => {
    setLoading(true)
    if (connecting) return

    setConnecting(true)

    socket.disconnect()

    socket.io.opts.extraHeaders = {
      Authorization: `Bearer ${getAuthorization()}`,
    }

    socket.connect()

    sendUser(user.id)
    await getRoomsInvites()
    setConnecting(false)
  }

  /**
   * Reconnection function.
   *
   * @param name - Chat room name.
   * @notExported
   */
  const reconnect = async (name?: string) => {
    if (user && name) {
      await connect(user)
      await joinRoom(name, true)
    }
    if (user && !name) {
      setIsConnected(false)
      await connect(user)
      setIsConnected(true)
    }
  }

  /**
   * Emit user ID when connected.
   * This then sets secret for encrypting messages.
   *
   * @param userId - Account ID.
   * @notExported
   */
  const sendUser = (userId: number) => {
    socket.emit('user', userId, (status: string, secret: string) => {
      if (status === 'OK') {
        setChatSecret(secret)
        setIsConnected(true)
      }
      setLoading(false)
    })
  }

  /**
   * Join room function. Sets room data after successfull join.
   *
   * @param name - Room name.
   * @param noRedirect - Should user be redirected.
   * @notExported
   */
  const joinRoom = async (name: IChatRoom['name'], noRedirect?: boolean) => {
    if (socket.connected && user) {
      try {
        setLoading(true)
        socket.emit('joinRoom', name, (room: IChatRoom) => {
          setRoomData(room)
          if (!noRedirect) {
            navigate(`/chat/${room.name}`)
          }
        })
      } catch (error) {
        setError(error as IError)
      } finally {
        setLoading(false)
      }
    }
  }

  /**
   * Leave room function. Empties room data and updates userRooms list.
   *
   * @param name - Room name.
   * @notExported
   */
  const leaveRoom = async (name: IChatRoom['name']) => {
    if (socket.connected && user) {
      try {
        setLoading(true)
        socket.emit('leaveRoom', name, (success: boolean) => {
          if (success) {
            setRoomData(undefined)
            setUserRooms(userRooms.filter(room => room.name !== name))
            if (!location.pathname.includes('/assignments') && !location.pathname.includes('/workorders')) {
              navigate('/chat')
            }
          }
        })
      } catch (error) {
        setError(error as IError)
      } finally {
        setLoading(false)
      }
    }
  }

  /**
   * Encrypts and emits chat message to room.
   *
   * @param room - Room name.
   * @param message - Message text.
   * @notExported
   */
  const sendMessage = async (room: IChatMessage['roomName'], message: IChatMessage['message']) => {
    if (socket.connected && user && message && chatSecret) {
      try {
        setLoading(true)
        socket.emit('chat', room, Encrypt(message, chatSecret))
      } catch (error) {
        setError(error as IError)
      } finally {
        setLoading(false)
      }
    }
  }

  /**
   * Deletes a message with the given ID from the chat.
   *
   * @param messageId - The ID of the message to be deleted.
   * @return A promise that resolves when the message is successfully deleted.
   * @notExported
   */
  const deleteMessage = async (messageId: IChatMessage['id']) => {
    if (socket.connected && user) {
      try {
        setLoading(true)
        socket.emit('deleteChat', messageId)
      } catch (error) {
        setError(error as IError)
      } finally {
        setLoading(false)
      }
    }
  }

  /**
   * Create new room function.
   * Saves new room data and joins the room and updates userRooms list.
   *
   * @param data - Room data.
   * @param noRedirect - Should user be redirected.
   * @notExported
   */
  const createRoom = async (data: IChatRoom, noRedirect?: boolean) => {
    try {
      setLoading(true)
      const newRoom = await chatAPI.create(data)
      setUserRooms(await chatAPI.getRooms())
      await joinRoom(newRoom.name, noRedirect)
      setLoading(false)
    } catch (err) {
      const error = err as IError
      setLoading(false)
      setError(error)
      if (error.response && error.response.status !== 400) {
        throw error
      }
    }
  }

  /**
   * Save updated room data.
   *
   * @param data - Updated room data.
   * @returns Resulted room data.
   * @notExported
   */
  const saveRoom = async (data: IChatRoom) => {
    try {
      setLoading(true)
      const updatedRoom = await chatAPI.save(data)
      const newRooms = [...userRooms]
      const index = newRooms.findIndex(room => room.name === updatedRoom.name)
      newRooms[index] = updatedRoom
      setUserRooms(newRooms)
      setLoading(false)
      return updatedRoom
    } catch (error) {
      setLoading(false)
      setError(error as IError)
      throw error
    }
  }

  /**
   * Deletes room data and updates userRooms list.
   *
   * @param data - Room data to be deleted.
   * @notExported
   */
  const deleteRoom = async (data: IChatRoom) => {
    try {
      setLoading(true)
      await chatAPI.delete(data.id)
      setUserRooms(previous => previous.filter(room => room.id !== data.id))
      setUserInvites(previous => previous.filter(invite => invite.roomName !== data.name))
    } catch (error) {
      setError(error as IError)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Get users invites to rooms.
   *
   * @returns Returns if still connecting.
   * @notExported
   */
  const getRoomsInvites = async () => {
    if (connecting) return
    try {
      setLoading(true)
      setUserRooms(await chatAPI.getRooms())
      setUserInvites(await chatAPI.getInvites())
    } catch (error) {
      setError(error as IError)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Invite new acounts to chat room.
   *
   * @param room - Room name.
   * @param accountIds - Account IDs to invite.
   * @notExported
   */
  const invite = (room: IChatRoom['name'], accountIds: number[]) => {
    if (socket.connected && user) {
      try {
        setLoading(true)
        socket.emit('newInvite', room, accountIds)
      } catch (error) {
        setError(error as IError)
      } finally {
        setLoading(false)
      }
    }
  }

  /**
   * Get more messages for room.
   *
   * @param room - Room name.
   * @param lastMessage - Oldest visible message.
   * @notExported
   */
  const getMoreMessages = (room: IChatRoom['name'], lastMessage: IChatMessage) => {
    if (socket.connected && user && roomData && lastMessage) {
      try {
        setLoading(true)
        if (room === roomData.name) {
          socket.emit('moreMessages', room, lastMessage, (messages: IChatMessage[]) => {
            if (messages.length) {
              setRoomData(previous => {
                if (previous && previous.Messages) {
                  return {
                    ...previous,
                    Messages: [...previous.Messages, ...messages],
                  }
                }
              })
            }
          })
        }
      } catch (error) {
        setError(error as IError)
      } finally {
        setLoading(false)
      }
    }
  }

  /**
   * Send notification to user.
   *
   * @param type - Notification type.
   * @param accountId - Notification target account ID.
   * @param message - Notification message.
   * @notExported
   */
  const sendNotification = (type: string, accountId: number, message?: string) => {
    if (socket.connected && user) {
      try {
        socket.emit('notify', type, accountId, message)
      } catch (error) {
        setError(error as IError)
      } finally {
        setLoading(false)
      }
    }
  }

  const closeNotification = () => {
    setShowNotification(false)
  }

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (features && features.includes('chat')) {
        setAllowedUsers(orderBy(await chatAPI.getAllowed(controller), ['organizationId'], ['asc']))
      }
    })()

    return () => {
      controller.abort()
    }
  }, [isConnected, features])

  /**
   * Event handler for member join.
   *
   * @param incomingData - Room data.
   * @notExported
   */
  function onMemberJoin(incomingData: IChatRoom) {
    if (roomData && roomData.name === incomingData.name) {
      const newRoomData = cloneDeep(roomData)

      if (newRoomData && newRoomData.Members && incomingData.name === newRoomData.name) {
        setRoomData(incomingData)
      }
    }
  }

  /**
   * Event handler for member leave.
   *
   * @param leavingMember - Leaving chat member.
   * @notExported
   */
  function onMemberLeave(leavingMember: IChatMember) {
    const newRoomData = cloneDeep(roomData)
    if (newRoomData && newRoomData.Members && newRoomData.name === leavingMember.roomName) {
      newRoomData.Members = newRoomData.Members.filter(member => member.id !== leavingMember.id)

      setRoomData(newRoomData)
    }
  }

  /**
   * Event handler for new invite.
   *
   * @param member - Member invite.
   * @notExported
   */
  function onInvite(member: IChatMember) {
    setUserInvites(previous => [...previous, member])
  }

  /**
   * Event handler for room edit.
   *
   * @param updatedRoom - Updated room data.
   * @notExported
   */
  function onRoomEdit(updatedRoom: IChatRoom) {
    if (updatedRoom && userRooms) {
      const newUserRooms = cloneDeep(userRooms)
      const userIndex = newUserRooms.findIndex(room => room.id === updatedRoom.id)
      newUserRooms[userIndex] = updatedRoom
      setUserRooms(newUserRooms)

      if (roomData && roomData.id === updatedRoom.id) {
        setRoomData(updatedRoom)
      }
    }
  }

  /**
   * Event handler for room delete.
   *
   * @param deletedRoom - Deleted room name.
   * @notExported
   */
  function onRoomDelete(deletedRoom: string) {
    if (userRooms) {
      const newUserRooms = userRooms.filter(room => room.name !== deletedRoom)
      setUserRooms(newUserRooms)
    }

    if (userInvites) {
      const newUserInvites = userInvites.filter(room => room.roomName !== deletedRoom)
      setUserInvites(newUserInvites)
    }

    if (roomData && roomData.name === deletedRoom) {
      setRoomData(undefined)
    }
  }

  /**
   * Event handler for new message.
   *
   * @param message - New message.
   * @notExported
   */
  async function onNewMessage(message: IChatMessage) {
    if (roomData && roomData.name === message.roomName) {
      setRoomData(previous => {
        if (previous) {
          if (previous.Messages) {
            return { ...previous, Messages: [...previous.Messages, message] }
          } else {
            return { ...previous, Messages: [message] }
          }
        }
        return previous
      })

      const newUserRooms = cloneDeep(userRooms)
      if (newUserRooms) {
        const index = newUserRooms.findIndex(room => room.name === message.roomName)
        const newRoomData = newUserRooms[index]
        if (newRoomData) {
          if (newRoomData.Messages) {
            newRoomData.Messages.push(message)
          } else {
            newRoomData.Messages = [message]
          }
          newUserRooms[index] = newRoomData
          setUserRooms(newUserRooms)
        }
      }
      setNewMessage(new Date())
    } else if (user && !roomData) {
      setRoomData(await chatAPI.getRoom(message.roomName))
    }
  }

  /**
   * Deletes a message from the room's message list.
   *
   * @param {number} messageId - The ID of the message to be deleted.
   * @notExported
   */
  function onMessageDeleted(messageId: number) {
    if (roomData && roomData.Messages) {
      setRoomData({ ...roomData, Messages: roomData.Messages.filter(message => message.id !== messageId) })
    }
  }

  /**
   * Event handler for new notification.
   *
   * @param type - Notification type.
   * @param message - Notification message.
   * @param url - Notification url.
   * @param room - Notification room name.
   * @notExported
   */
  function onNewNotification(type: string, message: string, url?: string, room?: string) {
    switch (type) {
      case 'newMessage':
        if (room && url && ((roomData && roomData.name !== room) || !roomData)) {
          setShowNotification(true)
          setNotificationData({
            message: `${t(message)}: ${room}`,
            type: 'success',
            duration: 'short',
            action: <CaleoYellowButton label={t('chat.goto')} valid clickAction={() => navigate(url)} />,
          })
        }
        break

      case 'general':
        setShowNotification(true)
        setNotificationData({
          message,
          type: 'success',
          duration: 'long',
        })
        break

      default:
        break
    }
  }

  /**
   * Event handler for disconnection.
   * @notExported
   */
  function onDisconnect() {
    setIsConnected(false)
  }

  /**
   * Event handler for error.
   *
   * @param error - Error details.
   * @notExported
   */
  function onError(error: IError) {
    console.error('WebSocket error', error)
    setError(error)
  }

  useEffect(() => {
    socket.on('memberJoin', onMemberJoin)
    socket.on('memberLeave', onMemberLeave)
    socket.on('invite', onInvite)
    socket.on('roomEdit', onRoomEdit)
    socket.on('roomDelete', onRoomDelete)
    socket.on('newMessage', onNewMessage)
    socket.on('messageDeleted', onMessageDeleted)
    socket.on('newNotification', onNewNotification)
    socket.on('disconnect', onDisconnect)
    socket.on('error', onError)

    return () => {
      socket.off('memberJoin', onMemberJoin)
      socket.off('memberLeave', onMemberLeave)
      socket.off('invite', onInvite)
      socket.off('roomEdit', onRoomEdit)
      socket.off('roomDelete', onRoomDelete)
      socket.off('newMessage', onNewMessage)
      socket.off('messageDeleted', onMessageDeleted)
      socket.off('newNotification', onNewNotification)
      socket.off('disconnect', onDisconnect)
      socket.off('error', onError)
    }
  }, [isConnected, roomData, userRooms, userInvites, socket.connected, connecting])

  return (
    <Provider
      value={{
        isConnected,
        chatSecret,
        allowedUsers,
        userRooms,
        roomData,
        userInvites,
        newMessage,
        loading,
        error,
        fullyRendered,
        connecting,
        showNotification,
        notificationData,
        closeNotification,
        setFullyRendered,
        setIsConnected,
        setUserRooms,
        setRoomData,
        setUserInvites,
        setLoading,
        setError,
        connect,
        joinRoom,
        leaveRoom,
        sendMessage,
        deleteMessage,
        createRoom,
        saveRoom,
        deleteRoom,
        getRoomsInvites,
        sendUser,
        invite,
        getMoreMessages,
        sendNotification,
        reconnect,
      }}
    >
      {children}
    </Provider>
  )
}

export default ChatProvider
