import { SubAPI } from './API'
import { IPerson } from 'types/userInterfaces'
import {
  IAssignment,
  IAssignmentRole,
  IAssignmentRoleProposal,
  IAssignmentFavorite,
  IAssignmentRoleNote,
  IAssignmentStatistics,
  IAssignmentTemplate,
  IAssignmentRoleTemplate,
  IAssignmentContactPerson,
  FilterOptions,
  AssignmentQuery,
  WorkOrderResponseQuery,
  IWorkOrderResponse,
  IProposalCandidate,
} from 'types/assignmentInterfaces'
import { AssignmentId, AssignmentRoleId } from 'types/ids'
import { IChatRoom } from 'types/chatInterfaces'
import { SortingState } from '@tanstack/react-table'

/**
 * Common Date type fields
 * @notExported
 */
const DATE_FIELDS = ['startDate', 'endDate', 'deadline', 'createdAt', 'updatedAt']

/**
 * Assignment API
 * @notExported
 */
class AssignmentAPI extends SubAPI {
  /**
   * Retrieves a list of assignments based on provided filters.
   *
   * @param filters - Filters to apply to the assignment query.
   * @param controllers - Optional signal for request cancellation.
   * @returns Array of assignments.
   */
  public async getAssignments(
    filters: FilterOptions,
    limit?: number,
    page?: number,
    sorting?: SortingState,
    filter?: string,
    controllers?: AbortController
  ): Promise<AssignmentQuery> {
    const data = await this.api.get<AssignmentQuery, unknown>(
      `assignments/`,
      controllers ? { signal: controllers.signal } : undefined,
      {
        ...filters,
        limit,
        page,
        sorting,
        filter: filter ? encodeURI(filter) : undefined,
      }
    )
    return data && data.rows && data.rows.length ? AssignmentAPI.convertQueryFromAPI(data) : { count: 0, rows: [] }
  }

  /**
   * Get all assignments for organization
   *
   * @param organizationId - Organization ID
   * @returns
   */
  public async getOrganizationAssignments(
    organizationId: number,
    controller?: AbortController
  ): Promise<IAssignment[]> {
    const data = (await this.api.get(
      `assignments/organization/${organizationId}`,
      controller ? { signal: controller.signal } : undefined
    )) as unknown[]
    return data && data.length ? data.map(AssignmentAPI.convertFromAPI) : []
  }

  /**
   * Get list of assignments
   *
   * @returns Array of assignments
   */
  public async getAssignmentList(controller?: AbortController): Promise<IAssignment[]> {
    const data = (await this.api.get(
      `assignments/list`,
      controller ? { signal: controller.signal } : undefined
    )) as unknown[]
    return data && data.length ? data.map(AssignmentAPI.convertFromAPI) : []
  }

  /**
   * Get assignment by ID
   *
   * @param id - Assignment ID
   * @returns Assignment
   */
  public async getAssignmentsById(id: number, controller?: AbortController): Promise<IAssignment> {
    return AssignmentAPI.convertFromAPI(
      await this.api.get(`assignments/${id}`, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Get public assignment by ID
   *
   * @param publicId - Public ID
   * @returns Assignment
   */
  public async getPublicAssignmentsById(publicId: string, controller?: AbortController): Promise<IAssignment> {
    return AssignmentAPI.convertFromAPI(
      await this.api.get(`assignments/public/${publicId}`, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Get assignment roles by assignment ID
   *
   * @param assignmentId - Assignment ID
   * @returns Array of assignment roles
   */
  public getAssignmentRoles(assignmentId: AssignmentId, controller?: AbortController): Promise<IAssignmentRole[]> {
    return this.api.get(`assignments/${assignmentId}/roles`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get people allocated to assignment role
   *
   * @param assignmentId - Assignment ID
   * @returns Array of allocated people
   */
  public getAssignmentRoleAllocatedPeople(
    assignmentId: AssignmentId,
    controller?: AbortController
  ): Promise<IPerson[]> {
    return this.api.get(`assignments/${assignmentId}/people`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get assignment role by ID
   *
   * @param roleId - Assignment Role ID
   * @returns Assignment role
   */
  public getAssignmentRoleById(roleId: AssignmentRoleId, controller?: AbortController): Promise<IAssignmentRole> {
    return this.api.get(`assignments/role/${roleId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Update assignment
   *
   * @param item - Assignment data
   * @returns Updated assignment
   */
  public async save(item: IAssignment, controller?: AbortController): Promise<IAssignment> {
    return AssignmentAPI.convertFromAPI(
      await this.api.put(`assignments/${item.id}`, item, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Create assignment
   *
   * @param item - Assignment data
   * @returns Created assignment
   */
  public async create(item: Partial<IAssignment>, controller?: AbortController): Promise<IAssignment> {
    return AssignmentAPI.convertFromAPI(
      await this.api.post(`assignments`, item, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Create broker copy of assignment
   *
   * @param item - Assignment data
   * @returns Created assignment
   */
  public async copy(item: IAssignment, controller?: AbortController): Promise<IAssignment> {
    return AssignmentAPI.convertFromAPI(
      await this.api.post('assignments/brokercopy', item, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Delete assignment
   *
   * @param assignmentId - Assignment ID
   * @returns Promise of assignment delete
   */
  public async delete(assignmentId: AssignmentId, controller?: AbortController): Promise<void> {
    return this.api.delete(`assignments/${assignmentId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Create proposal to role.
   *
   * @param data - Proposal data
   * @returns Promise of proposal creation
   */
  public async proposeToRole(data: IAssignmentRoleProposal, controller?: AbortController): Promise<void> {
    return this.api.post(`assignments/propose`, data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get proposal edit data
   *
   * @param proposalId - Proposal ID
   * @returns Proposal data
   */
  public async getProposalEdit(proposalId: number, controller?: AbortController): Promise<IAssignmentRoleProposal> {
    return this.api.get(`assignments/proposal/${proposalId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Update proposal
   *
   * @param data - Proposal data
   * @returns Promise of proposal update
   */
  public async updateProposal(data: IAssignmentRoleProposal, controller?: AbortController): Promise<void> {
    return this.api.put(`assignments/proposal/${data.id}`, data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Delete proposal allocation
   *
   * @param allocationId - Allocation ID
   * @returns Promise of allocation delete
   */
  public async allocationDelete(allocationId: number, controller?: AbortController): Promise<void> {
    return this.api.delete(
      `assignments/proposal/allocation/${allocationId}`,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Delete proposal attachment
   *
   * @param attachmentId - Attachment ID
   * @returns Promise of attachment delete
   */
  public async attachmentDelete(attachmentId: number, controller?: AbortController): Promise<void> {
    return this.api.delete(
      `assignments/proposal/attachment/${attachmentId}`,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Get list of sales people
   *
   * @returns Array of sales people
   */
  public async getSalesPeople(controller?: AbortController): Promise<IPerson[]> {
    return this.api.get('assignments/sales', controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get proposed candidates for assignment
   *
   * @param id - Assignment ID
   * @returns Array of assignment roles with proposed candidates
   */
  public async getAssignmentProposed(id: AssignmentId, controller?: AbortController): Promise<IAssignmentRole[]> {
    return this.api.get(`assignments/${id}/proposed`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get list of favorite assignments
   *
   * @returns Array of favorite assignments
   */
  public async getFavorites(controller?: AbortController): Promise<IAssignmentFavorite[]> {
    return this.api.get('assignments/favorite', controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Set assignment as favorite
   *
   * @param assignmentId - Assignment ID
   * @returns Promise of favorite assignment
   */
  public async setFavorite(assignmentId: AssignmentId, controller?: AbortController): Promise<void> {
    return this.api.put(
      `assignments/favorite/${assignmentId}`,
      {},
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Create note for assignment role
   *
   * @param item - Role note
   * @returns Created role note
   */
  public async createNote(
    item: Partial<IAssignmentRoleNote>,
    controller?: AbortController
  ): Promise<IAssignmentRoleNote> {
    return this.api.post(`assignments/role/note`, item, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get notes for assignment role
   *
   * @param id - Role ID
   * @returns Array of role notes
   */
  public async getRoleNotes(id: AssignmentRoleId, controller?: AbortController): Promise<IAssignmentRoleNote[]> {
    return this.api.get(`assignments/role/${id}/note`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get assignment statistics
   *
   * @param start - Start date of statistics
   * @param end - End date of statistics
   * @param networkId - Network ID
   * @returns Assignment statistics
   */
  public async getStatistics(
    start: Date,
    end: Date,
    networkId: number,
    controller?: AbortController
  ): Promise<IAssignmentStatistics> {
    return this.api.post(
      'assignments/statistics',
      { start, end, networkId },
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Get public assignment URL
   *
   * @param assignmentId - Assignment ID
   * @returns Public URL
   */
  public async getPublicUrl(assignmentId: number, controller?: AbortController): Promise<string> {
    return this.api.get(`assignments/publicUrl/${assignmentId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Change assignment status
   *
   * @param assignmentId - Assignment ID
   * @param status - New status
   * @returns Promise of assignment status change
   */
  public async changeStatus(assignmentId: number, status: string, controller?: AbortController): Promise<void> {
    return this.api.put(
      `assignments/status/${assignmentId}`,
      { status },
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Get organization proposals
   *
   * @param organizationId - Organization ID
   * @returns Array of proposals
   */
  public async getOrganizationProposals(
    organizationId: number,
    controller?: AbortController
  ): Promise<IAssignmentRoleProposal[]> {
    return this.api.get(
      `assignments/proposals/${organizationId}`,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Reject proposal
   *
   * @param proposalId - Proposal ID
   * @param rejectMessage - Optional reject message
   * @returns Promise of proposal rejection
   */
  public async rejectProposal(proposalId: number, rejectMessage, controller?: AbortController): Promise<void> {
    return this.api.put(
      `assignments/proposals/reject/${proposalId}`,
      { message: rejectMessage },
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Accept proposal
   *
   * @param proposalId - Proposal ID
   * @returns Promise of proposal acceptance
   */
  public async acceptProposal(proposalId: number, controller?: AbortController): Promise<void> {
    return this.api.put(
      `assignments/proposals/accept/${proposalId}`,
      {},
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Get proposal contact person
   *
   * @param proposalId - Proposal ID
   * @returns Contact person
   */
  public async getProposalContactPerson(
    proposalId: number,
    controller?: AbortController
  ): Promise<IAssignmentContactPerson> {
    return this.api.get(
      `assignments/proposals/contact/${proposalId}`,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Get proposal chat room
   *
   * @param proposalId - Proposal ID
   * @returns Chat room
   */
  public async getProposalChatRoom(proposalId: number, controller?: AbortController): Promise<IChatRoom> {
    return this.api.get(
      `assignments/proposals/room/${proposalId}`,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Get proposal.
   *
   * @param proposalId - Proposal ID
   * @returns Proposal
   */
  public async getProposal(proposalId: number, controller?: AbortController): Promise<IAssignmentRoleProposal> {
    return this.api.get(`assignments/proposal/${proposalId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get assignment chat room
   *
   * @param assignmentId - Assignment ID
   * @returns Chat room
   */
  public async getAssignmentChatRoom(assignmentId: number, controller?: AbortController): Promise<IChatRoom> {
    return this.api.get(`assignments/room/${assignmentId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get assignment templates
   *
   * @param language - Template language
   * @returns Array of assignment templates
   */
  public async getAssignmentTemplates(language: string, controller?: AbortController): Promise<IAssignmentTemplate[]> {
    return this.api.get(`assignments/templates/${language}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Update assignment template
   *
   * @param data - Assignment template data
   * @returns Updated assignment template
   */
  public async updateAssignmentTemplate(data: IAssignmentTemplate): Promise<IAssignmentTemplate> {
    return this.api.put(`assignments/template/${data.id}`, data)
  }

  /**
   * Create assignment template
   *
   * @param data - Assignment template data
   * @returns Created assignment template
   */
  public async createAssignmentTemplate(data: IAssignmentTemplate): Promise<IAssignmentTemplate> {
    return this.api.post(`assignments/template`, data)
  }

  /**
   * Get role templates
   *
   * @param language - Template language
   * @returns Array of role templates
   */
  public async getRoleTemplates(language: string, controller?: AbortController): Promise<IAssignmentRoleTemplate[]> {
    return this.api.get(
      `assignments/role/templates/${language}`,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Update role template
   *
   * @param data - Role template data
   * @returns Updated role template
   */
  public async updateRoleTemplate(data: IAssignmentRoleTemplate): Promise<IAssignmentRoleTemplate> {
    return this.api.put(`assignments/role/template/${data.id}`, data)
  }

  /**
   * Create role template
   *
   * @param data - Role template data
   * @returns Created role template
   */
  public async createRoleTemplate(data: IAssignmentRoleTemplate): Promise<IAssignmentRoleTemplate> {
    return this.api.post(`assignments/role/template`, data)
  }

  /**
   * Convert raw assignment data from API to Assignment data.
   *
   * @param rawData - Assignment data from API
   * @returns Assignment data
   */
  static convertFromAPI(rawData: unknown): IAssignment {
    if (typeof rawData !== 'object') {
      throw new Error('rawData was not an object')
    }

    const data = rawData as Record<string, string | Date | number | null>

    for (const field of DATE_FIELDS) {
      const currentValue = data[field]

      if (currentValue instanceof Date) {
        continue
      }

      if (currentValue === null) {
        continue
      }

      data[field] = new Date(currentValue)
    }

    if (!data.workloadEstimate) {
      data.workloadEstimate = 0
    }

    return data as unknown as IAssignment
  }

  /**
   * Convert raw assignment query data from API to AssignmentQuery data.
   *
   * @param rawData - Assignment query data from API
   * @returns AssignmentQuery data
   */
  static convertQueryFromAPI(rawData: AssignmentQuery): AssignmentQuery {
    if (typeof rawData !== 'object') {
      throw new Error('rawData was not an object')
    }

    if (!Array.isArray(rawData.rows)) {
      throw new Error('rawData.rows was not an array')
    }

    for (let row of rawData.rows) {
      row = AssignmentAPI.convertFromAPI(row)
    }

    return rawData
  }

  static convertToResponse(rawData: unknown): IWorkOrderResponse {
    if (typeof rawData !== 'object') {
      throw new Error('rawData was not an object')
    }

    const data = rawData as Record<string, string | Date | number | null>

    const dateFields = ['acceptedAt', 'createdAt', 'updatedAt']

    for (const field of dateFields) {
      const currentValue = data[field]

      if (currentValue instanceof Date) {
        continue
      }

      if (currentValue === null) {
        continue
      }

      data[field] = new Date(currentValue)
    }

    if (!data.workloadEstimate) {
      data.workloadEstimate = 0
    }

    return data as unknown as IWorkOrderResponse
  }

  /**
   * Get all WorkOrdersResponses for user.
   *
   * @returns List of WorkOrderResponses
   */
  public async getWorkOrders(
    limit?: number,
    page?: number,
    sorting?: SortingState,
    filter?: string,
    controller?: AbortController
  ): Promise<WorkOrderResponseQuery> {
    return this.api.get('assignments/workorders', controller ? { signal: controller.signal } : undefined, {
      limit,
      page,
      sorting,
      filter: filter ? encodeURI(filter) : undefined,
    })
  }

  /**
   * Get WorkOrderResponse with ID.
   *
   * @param id - WorkOrderResponse ID
   * @returns WorkOrderResponse data
   */
  public async getWorkOrder(id: number, controller?: AbortController): Promise<IWorkOrderResponse> {
    return AssignmentAPI.convertToResponse(
      await this.api.get(`assignments/workorders/${id}`, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Update WorkOrderResponse person.
   *
   * @param id - WorkOrderResponse ID
   * @param personId - Person ID
   * @returns Updated WorkOrderResponse
   */
  public async updateWorkOrderPerson(
    id: number,
    personId: number,
    organizationId: number,
    controller?: AbortController
  ): Promise<IWorkOrderResponse> {
    return AssignmentAPI.convertToResponse(
      await this.api.put(
        `assignments/workorders/${id}`,
        { personId, organizationId },
        controller ? { signal: controller.signal } : undefined
      )
    )
  }

  /**
   * Respond to WorkOrderResponse as supplier.
   *
   * @param id - WorkOrderResponse ID
   * @param response - WorkOrderResponse response
   * @param subcontractor - is person from subcontractor
   * @returns Updated WorkOrderResponse
   */
  public async respondToWorkOrder(
    id: number,
    response: boolean,
    subcontractor?: boolean,
    controller?: AbortController
  ): Promise<IWorkOrderResponse> {
    return AssignmentAPI.convertToResponse(
      await this.api.post(
        `assignments/workorders/${id}`,
        { response, subcontractor },
        controller ? { signal: controller.signal } : undefined
      )
    )
  }

  /**
   * Get WorkOrderResponse chat room
   *
   * @param assignmentId - WorkOrderResponse ID
   * @returns Chat room
   */
  public async getWorkOrderResponseChatRoom(responseId: number, controller?: AbortController): Promise<IChatRoom> {
    return this.api.get(
      `assignments/workorders/room/${responseId}`,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Reject WorkOrderResponse
   *
   * @param id - WorkOrderResponse ID
   * @param rejectMessage - WorkOrderResponse reject message
   * @returns Updated WorkOrderResponse
   */
  public async rejectWorkOrder(
    id: number,
    rejectMessage: string,
    controller?: AbortController
  ): Promise<IWorkOrderResponse> {
    return AssignmentAPI.convertToResponse(
      await this.api.post(
        `assignments/workorders/reject/${id}`,
        { rejectMessage },
        controller ? { signal: controller.signal } : undefined
      )
    )
  }

  /**
   * Accept WorkOrderResponse
   *
   * @param id - WorkOrderResponse ID
   * @returns Updated WorkOrderResponse
   */
  public async acceptWorkOrder(id: number, controller?: AbortController): Promise<IWorkOrderResponse> {
    return AssignmentAPI.convertToResponse(
      await this.api.post(
        `assignments/workorders/accept/${id}`,
        {},
        controller ? { signal: controller.signal } : undefined
      )
    )
  }

  /**
   * Update rating of WorkOrderResponse.
   *
   * @param id - WorkOrderResponse ID
   * @param rating - WorkOrderResponse rating
   * @returns Updated WorkOrderResponse
   */
  public async updateResponseRating(
    id: number,
    rating: number,
    controller?: AbortController
  ): Promise<IWorkOrderResponse> {
    return AssignmentAPI.convertToResponse(
      await this.api.put(
        `assignments/workorders/rating/${id}`,
        { rating },
        controller ? { signal: controller.signal } : undefined
      )
    )
  }

  /**
   * Update rating of ProposalCandidate.
   *
   * @param id - ProposalCandidate ID
   * @param rating - ProposalCandidate rating
   * @returns Updated ProposalCandidate
   */
  public async updateCandidateRating(
    id: number,
    rating: number,
    controller?: AbortController
  ): Promise<IProposalCandidate> {
    return this.api.put(
      `assignments/proposals/rating/${id}`,
      { rating },
      controller ? { signal: controller.signal } : undefined
    )
  }
}

export const assignmentAPI = new AssignmentAPI()
