import { IAllocation, IAllocationData, IAllocationFilters, IOverviewData } from 'types/allocationInterfaces'
import { SubAPI } from './API'

type AllocationSubObject = {
  id: number
}

interface AllocationSubAPIOptions<T> {
  dateFields?: (keyof T)[]
}

/**
 * Allocation sub API.
 *
 * @notExported
 */
class AllocationSubAPI<T extends AllocationSubObject> extends SubAPI {
  options?: AllocationSubAPIOptions<T>

  constructor(options?: AllocationSubAPIOptions<T>) {
    super()

    this.options = options
  }

  /**
   * Gets all allocations for a person.
   *
   * @param id - Person ID
   * @returns Array of allocations for the person.
   */
  public async getAllocations(id: number, controller?: AbortController): Promise<T[]> {
    const data = (await this.api.get(
      `allocations/person/${id}`,
      controller ? { signal: controller.signal } : undefined
    )) as T[]
    return data && data.length ? data.map(item => this.convertFromAPI(item)) : []
  }

  public async getSupplierAllocations(controller?: AbortController): Promise<T[]> {
    const data = (await this.api.get(
      'allocations/suppliers',
      controller ? { signal: controller.signal } : undefined
    )) as T[]
    return data && data.length ? data.map(item => this.convertFromAPI(item)) : []
  }

  /**
   * Gets all allocations for a public ID.
   *
   * @param publicId - Public ID of a person.
   * @returns Array of allocations.
   */
  public async getAllocationsForPublicId(publicId: string, controller?: AbortController): Promise<T[]> {
    const data = (await this.api.get(
      `allocations/publicId/${publicId}`,
      controller ? { signal: controller.signal } : undefined
    )) as T[]

    return data && data.length ? data.map(item => this.convertFromAPI(item)) : []
  }

  /**
   * Gets overview data for allocations.
   *
   * @param data - Data object.
   * @param filters - Filters of data(type of allocations).
   * @param timeScope - Time scope of data(next 1-4 months).
   * @param scope - Scope of data(organization or team).
   * @returns Overview data
   */
  public async getOverviewData(data: unknown, controller?: AbortController): Promise<IOverviewData[]> {
    return await this.api.post(`allocations/overview`, data, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Gets allocation blocks for a person.
   *
   * @param personId - Person ID.
   * @param timeScope - Time frame of allocation blocks.
   * @param filters - Type of allocations to display.
   * @returns Allocation block data.
   */
  public async getPersonAllocationBlocks(
    personId: number,
    timeScope: number,
    filters: IAllocationFilters,
    controller?: AbortController
  ): Promise<IAllocationData[]> {
    const data = (await this.api.post(
      `allocations/blocks/${personId}/${timeScope}`,
      { filters },
      controller ? { signal: controller.signal } : undefined
    )) as T[]

    return data && data.length ? (data.map(item => this.convertFromAPI(item) as unknown) as IAllocationData[]) : []
  }

  /**
   * Gets draggable allocation blocks for a person.
   *
   * @param personId - Person ID.
   * @returns Allocation blocks data.
   */
  public async getPersonDraggableAllocationBlocks(
    personId: number,
    controller?: AbortController
  ): Promise<IAllocationData[]> {
    const data = (await this.api.get(
      `allocations/draggable/${personId}`,
      controller ? { signal: controller.signal } : undefined
    )) as T[]
    return data && data.length ? (data.map(item => this.convertFromAPI(item) as unknown) as IAllocationData[]) : []
  }

  /**
   * Creates an allocation.
   *
   * @param data - Allocation data.
   * @returns Created allocation.
   */
  public async create(data: unknown, controller?: AbortController): Promise<T> {
    return this.convertFromAPI(
      this.api.post(`allocations/`, data, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Creates an allocation with type other.
   *
   * @param data - Allocation data.
   * @returns Created allocation.
   */
  public async createOther(data: unknown, controller?: AbortController): Promise<T> {
    return this.convertFromAPI(
      this.api.post(`allocations/other/`, data, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Updates an allocation.
   *
   * @param data - Allocation data.
   * @returns Updated allocation.
   */
  public async save(data: IAllocation, controller?: AbortController): Promise<T> {
    return this.convertFromAPI(
      await this.api.put(`/allocations/${data.id}`, data, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Updates an allocation with type other.
   *
   * @param data - Allocation data.
   * @returns Updated allocation.
   */
  public async saveOther(data: IAllocation, controller?: AbortController): Promise<T> {
    return this.convertFromAPI(
      await this.api.put(`/allocations/other/${data.id}`, data, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Deletes an allocation.
   *
   * @param id - Allocation ID.
   * @returns Success message.
   */
  public async delete(id: number, controller?: AbortController): Promise<string> {
    return this.api.delete(`allocations/${id}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Converts data from API to a more usable format.
   *
   * @param rawData - Raw data from API.
   * @returns Converted data.
   */
  convertFromAPI(rawData: unknown): T {
    if (typeof rawData !== 'object' || rawData === null) {
      throw new Error('API result was unexpectedly not an object!')
    }

    const data = rawData as Record<keyof T, string | Date | null>

    for (const field of this.options?.dateFields ?? []) {
      const currentValue = data[field] as string | Date | null
      if (currentValue instanceof Date) {
        continue
      }

      if (currentValue === null) {
        continue
      }

      data[field] = new Date(currentValue)
    }

    return data as unknown as T
  }
}

export const allocationAPI = new AllocationSubAPI<IAllocation>({
  dateFields: ['startDate', 'endDate'],
})
