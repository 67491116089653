import i18n from 'i18next'
import { Crop } from 'react-image-crop'
import { toInteger } from 'lodash'
import Fuse, { FuseOptionKey } from 'fuse.js'
import { green, amber, purple, teal, grey } from '@mui/material/colors'

/**
 * Converts a data URL to a File object.
 *
 * @param {string | ArrayBuffer} dataurl - The data URL to convert.
 * @param {string} filename - The name of the file.
 * @return {File} The converted File object.
 */
const dataURLtoFile = (dataurl: string | ArrayBuffer, filename: string): File => {
  const arr = dataurl.toString().split(',')
  if (arr.length < 2) {
    throw new Error('Invalid dataurl')
  }

  const match = arr[0].match(/:(.*?);/)
  if (!match) {
    throw new Error('Invalid dataurl mime part')
  }

  const mime = match[1],
    bstr = atob(arr[1])

  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

/**
 * Returns an array of language levels.
 *
 * @return {Array} An array containing objects representing language levels.
 */
export const languageLevels = () => {
  return [
    {
      level: 1,
      label: `A2 - ${i18n.t('language.basic')}`,
      description: i18n.t('language.description.a2'),
    },
    {
      level: 2,
      label: `B1 - ${i18n.t('language.moderate')}`,
      description: i18n.t('language.description.b1'),
    },
    {
      level: 3,
      label: `B2 - ${i18n.t('language.good')}`,
      description: i18n.t('language.description.b2'),
    },
    {
      level: 4,
      label: `C1 - ${i18n.t('language.excellent')}`,
      description: i18n.t('language.description.c1'),
    },
    {
      level: 5,
      label: `C2 - ${i18n.t('language.native')}`,
      description: i18n.t('language.description.c2'),
    },
  ]
}

/**
 * Returns an array of organization search types.
 *
 * @return {Array} An array containing objects with the properties `value` and `label`.
 */
export const organizationSearchTypes = () => {
  return [
    {
      value: 'SEARCH_USERS_ORGANIZATIONS',
      label: `${i18n.t('resources.searchEmployees.userOrganizations')}`,
    },
    {
      value: 'SEARCH_NETWORK_ORGANIZATIONS',
      label: `${i18n.t('resources.searchEmployees.networkOrganizations')}`,
    },
    {
      value: 'SEARCH_ALL_ORGANIZATIONS',
      label: `${i18n.t('resources.searchEmployees.allOrganizations')}`,
    },
  ]
}

export interface ISkillType {
  value: string
  label: string
}

/**
 * Returns an array of skill types.
 *
 * @return {Array} An array of objects representing different skill types.
 */
export const skillTypes = () => {
  return [
    {
      value: 'skill',
      label: `${i18n.t('reports.skillsStatistics.type.skills')}`,
    },
    {
      value: 'industry',
      label: `${i18n.t('reports.skillsStatistics.type.industries')}`,
    },
    {
      value: 'role',
      label: `${i18n.t('reports.skillsStatistics.type.roles')}`,
    },
  ]
}

/**
 * Retrieves the skill levels based on the specified type.
 *
 * @param {string} type - Optional. The type of skill levels to retrieve. Valid values are 'skill', 'industry', or 'role'.
 * @return {Array} An array of skill level objects, each containing a level, label, and description.
 */
export const skillLevels = (type?: 'skill' | 'industry' | 'role') => {
  return [
    {
      level: 1,
      label: `${i18n.t('profile.skill.fundamental')}`,
      description: `${i18n.t(`profile.${type ?? 'skill'}.fundamental.description`)}`,
    },
    {
      level: 2,
      label: `${i18n.t('profile.skill.novice')}`,
      description: `${i18n.t(`profile.${type ?? 'skill'}.novice.description`)}`,
    },
    {
      level: 3,
      label: `${i18n.t('profile.skill.intermediate')}`,
      description: `${i18n.t(`profile.${type ?? 'skill'}.intermediate.description`)}`,
    },
    {
      level: 4,
      label: `${i18n.t('profile.skill.advanced')}`,
      description: `${i18n.t(`profile.${type ?? 'skill'}.advanced.description`)}`,
    },
    {
      level: 5,
      label: `${i18n.t('profile.skill.expert')}`,
      description: `${i18n.t(`profile.${type ?? 'skill'}.expert.description`)}`,
    },
  ]
}

/**
 * Returns an array of skill interest levels.
 *
 * @return {Array} An array of skill interest levels.
 */
export const skillInterestLevels = () => {
  return [
    {
      level: 1,
      label: `${i18n.t('profile.skill.interest.low')}`,
    },
    {
      level: 2,
      label: `${i18n.t('profile.skill.interest.slight')}`,
    },
    {
      level: 3,
      label: `${i18n.t('profile.skill.interest.medium')}`,
    },
    {
      level: 4,
      label: `${i18n.t('profile.skill.interest.high')}`,
    },
    {
      level: 5,
      label: `${i18n.t('profile.skill.interest.veryHigh')}`,
    },
  ]
}

/**
 * Generates an array of allocation states.
 *
 * @return {Array} An array of objects representing allocation states.
 */
export const allocationStates = () => {
  return [
    {
      state: 0,
      label: `${i18n.t('allocation.state.0')}`,
    },
    {
      state: 1,
      label: `${i18n.t('allocation.state.1')}`,
    },
  ]
}

/**
 * Returns an array of admin permission group types.
 *
 * @return {Array} An array of objects representing the different admin permission group types.
 */
export const adminPermissionGroupTypes = () => {
  return [
    {
      value: 'admin',
      label: i18n.t('admin.permission.groupType.admin'),
    },
    {
      value: 'inputDataManager',
      label: i18n.t('admin.permission.groupType.inputDataManager'),
    },
    {
      value: 'companyAdmin',
      label: i18n.t('admin.permission.groupType.companyAdmin'),
    },
    {
      value: 'sales',
      label: i18n.t('admin.permission.groupType.sales'),
    },
    {
      value: 'freelancer',
      label: i18n.t('admin.permission.groupType.freelancer'),
    },
    {
      value: 'recruit',
      label: i18n.t('admin.permission.groupType.recruit'),
    },
    {
      value: 'teamLeader',
      label: i18n.t('admin.permission.groupType.teamLeader'),
    },
  ]
}

/**
 * Retrieves a cropped image from the provided image based on the given crop dimensions.
 *
 * @param {Crop} crop - The crop dimensions specifying the area of the image to be cropped.
 * @param {HTMLImageElement} image - The image from which to retrieve the cropped image.
 * @return {Promise<File | undefined>} A promise that resolves to the cropped image file, or undefined if the crop fails.
 */
export async function getCroppedImg(crop: Crop, image: HTMLImageElement): Promise<File | undefined> {
  const wanted = {
    width: Math.min(220, image.width, image.height),
    height: Math.min(220, image.width, image.height),
  }

  if (!crop.width || !crop.height || crop.x === undefined || crop.y === undefined) {
    throw 'Bad crop'
  }

  const canvas = document.createElement('canvas')
  canvas.width = wanted.width
  canvas.height = wanted.height

  const ctx = canvas.getContext('2d')
  if (!ctx) {
    throw 'Canvas context not supported'
  }

  ctx.drawImage(
    image,
    (crop.x / 100) * image.width,
    (crop.y / 100) * image.height,
    (crop.width / 100) * image.width,
    (crop.height / 100) * image.height,
    0,
    0,
    canvas.width,
    canvas.height
  )

  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (!blob) {
        return reject('No blob')
      }

      reader.onload = () => {
        if (!reader.result) {
          return reject('No result buffer!')
        }
        resolve(dataURLtoFile(reader.result, 'cropped.png'))
      }
      reader.onerror = err => {
        reject(`Error processing crop to file: ${err}`)
      }
      reader.readAsDataURL(blob)
    }, 'image/png')
  })
}

/**
 * Converts a given date to a display date based on the specified resolution.
 *
 * @param {Date | number | string} date - The date to be converted.
 * @param {string} [resolution='day'] - The resolution of the display date. Defaults to 'day'.
 * @return {string} - The converted display date.
 */
export const convertToDisplayDate = (date: Date | number | string, resolution = 'day') => {
  let locale: string

  switch (i18n.language) {
    case 'fi':
      locale = 'fi-FI'
      break

    case 'en':
      locale = 'en-GB'
      break

    case 'sv':
      locale = 'sv-SE'
      break

    default:
      locale = 'fi-FI'
      break
  }

  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: resolution === 'month' || resolution === 'day' || resolution === 'time' ? 'numeric' : undefined,
    day: resolution === 'day' || resolution === 'time' ? 'numeric' : undefined,
    hour: resolution === 'time' ? '2-digit' : undefined,
    minute: resolution === 'time' ? '2-digit' : undefined,
  }).format(new Date(date))
}

/**
 * Returns the date format based on the given locale and resolution.
 *
 * @param {string} locale - The locale to determine the date format for.
 * @param {string} [resolution='day'] - The resolution of the date format.
 * @return {string} The date format based on the locale and resolution.
 */
export const getDateFormatByLocaleAndResolution = (locale: string, resolution = 'day') => {
  const days = 'dd'
  const months = 'MM'
  const years = 'yyyy'

  switch (resolution) {
    case 'year':
      return years
    case 'month':
      if (locale === 'fi' || locale === 'en') {
        return `${months}.${years}`
      } else if (locale === 'sv') {
        return `${years}-${months}`
      } else {
        return `${months}.${years}`
      }
    case 'day':
    default:
      if (locale === 'fi' || locale === 'en') {
        return `${days}.${months}.${years}`
      } else if (locale === 'sv') {
        return `${years}-${months}-${days}`
      } else {
        return `${days}.${months}.${years}`
      }
  }
}

/**
 * Sets the individual values of a Date object.
 *
 * @param {Date} date - The Date object to modify.
 * @param {number} year - The year value to set.
 * @param {number} month - The month value (0-11) to set.
 * @param {number} day - The day value (1-31) to set.
 * @param {number} hours - The hours value (0-23) to set.
 * @param {number} minutes - The minutes value (0-59) to set.
 * @param {number} seconds - The seconds value (0-59) to set.
 * @param {number} millis - The milliseconds value (0-999) to set.
 */
const setDateValues = (
  date: Date,
  year: number,
  month: number,
  day: number,
  hours: number,
  minutes: number,
  seconds: number,
  millis: number
) => {
  date.setFullYear(year)
  date.setMonth(month)
  date.setDate(day)
  date.setHours(hours)
  date.setMinutes(minutes)
  date.setSeconds(seconds)
  date.setMilliseconds(millis)
}

/**
 * Formats the end date based on the given resolution.
 *
 * @param {Date} date - The date to format.
 * @param {string} resolution - The resolution of the date formatting. Defaults to 'day'.
 * @return {Date} - The formatted end date.
 */
export const formatEndDate = (date: Date, resolution = 'day'): Date => {
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = resolution === 'year' || resolution === 'month' ? 0 : date.getDate()

  if (resolution === 'year') setDateValues(date, year, 12, day, 23, 59, 59, 0)
  else if (resolution === 'month') setDateValues(date, year, month + 1, day, 23, 59, 59, 0)
  else setDateValues(date, year, month, day, 23, 59, 59, 0)

  return date
}

/**
 * Formats the start date based on the given resolution.
 *
 * @param {Date} date - The date to be formatted.
 * @param {string} resolution - The resolution to format the date. Defaults to 'day'.
 * @return {Date} - The formatted date.
 */
export const formatStartDate = (date: Date, resolution = 'day'): Date => {
  const year = date.getFullYear()
  const month = date.getMonth()

  const day = resolution === 'year' || resolution === 'month' ? 1 : date.getDate()

  if (resolution === 'year') {
    setDateValues(date, year, 0, day, 0, 0, 0, 0)
  } else {
    setDateValues(date, year, month, day, 0, 0, 0, 0)
  }

  return date
}

/**
 * Performs filtering on an array of options using Fuse.js.
 *
 * @param {object[]} options - The array of options to filter.
 * @param {string} inputValue - The input value to filter by.
 * @param {FuseOptionKey<string>[]} keys - The keys to search within the options.
 * @return {object[]} The filtered array of options.
 */
export const fuseFiltering = (options, inputValue: string, keys: FuseOptionKey<string>[]) => {
  if (inputValue && inputValue.length) {
    const fuseOptions = {
      includeScore: true,
      threshold: 0.4,
      keys: keys,
    }
    const fuse = new Fuse(options, fuseOptions)
    const filtered = fuse.search(inputValue).map(result => result.item)
    return filtered.length ? filtered : []
  }

  return options
}

/**
 * Rounds a number to a specified number of decimal places.
 *
 * @param {number} number - The number to round.
 * @param {number} decimals - The number of decimal places to round to.
 * @return {number} - The rounded number.
 */
export const roundToDecimal = (number: number, decimals: number) => {
  const multiplier = Math.pow(10, decimals || 0)
  return Math.round(number * multiplier) / multiplier
}

/**
 * Returns a formatted label for the given skill experience in months.
 *
 * @param {number} months - The number of months of skill experience.
 * @param {boolean} [abbreviation=false] - Indicates whether to use abbreviations for the label.
 * @return {string} The formatted label for the skill experience.
 */
export const getSkillExperienceLabel = (months: number, abbreviation = false) => {
  const monthSuffix = abbreviation ? i18n.t('profile.skill.month.abbr') : i18n.t('profile.skill.month')
  const monthsSuffix = abbreviation ? i18n.t('profile.skill.months.abbr') : i18n.t('profile.skill.months')
  const yearSuffix = abbreviation ? i18n.t('profile.skill.years.abbr') : i18n.t('profile.skill.year')
  const yearsSuffix = abbreviation ? i18n.t('profile.skill.years.abbr') : i18n.t('profile.skill.years')
  if (months < 1) {
    return `~ 1 ${monthSuffix}`
  } else if (months % 1 !== 0) {
    const value = Math.round(months)
    return `~ ${value} ${value === 1 ? monthSuffix : monthsSuffix}`
  } else {
    const fullYears = Math.floor(months / 12)
    const fullMonths = months % 12
    const yearsLabel = `${fullYears} ${fullYears === 1 ? yearSuffix : yearsSuffix}`
    const monthsLabel = `${fullMonths} ${fullMonths === 1 ? monthSuffix : monthsSuffix}`
    if (fullYears < 1) {
      return monthsLabel
    } else if (fullMonths < 1) {
      return yearsLabel
    } else {
      return `${yearsLabel} ${monthsLabel}`
    }
  }
}

/**
 * Converts a number to a positive integer.
 *
 * @param {number} number - The number to be converted.
 * @return {number} The converted positive integer value.
 */
export const toPositiveInteger = (number: number) => {
  return Math.abs(toInteger(number))
}

/**
 * A function that lightens or darkens a given color by a specified amount.
 *
 * @param {string} color - The color to be lightened or darkened.
 * @param {number} amount - The amount by which to lighten or darken the color.
 * @return {string} The modified color.
 */
export const lightenDarkenColor = function (color: string, amount: number) {
  let usePound = false

  if (color[0] == '#') {
    color = color.slice(1)
    usePound = true
  }

  const num = parseInt(color, 16)

  let r = (num >> 16) + amount

  if (r > 255) r = 255
  else if (r < 0) r = 0

  let b = ((num >> 8) & 0x00ff) + amount

  if (b > 255) b = 255
  else if (b < 0) b = 0

  let g = (num & 0x0000ff) + amount

  if (g > 255) g = 255
  else if (g < 0) g = 0

  return (usePound ? '#' : '') + padWithZeros((g | (b << 8) | (r << 16)).toString(16), 6)
}

const padWithZeros = (number: string, length: number) => {
  return number.padStart(length, '0')
}

/**
 * Validates an email address.
 *
 * @param {string} email - The email address to validate.
 * @return {boolean} Returns true if the email address is valid, false otherwise.
 */
export const validateEmail = (email: string) => {
  if (email.length < 5) return false

  const regExp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

  return regExp.test(String(email).toLowerCase())
}

/**
 * Returns the color associated with a skill level.
 *
 * @param {number} level - The level of the skill.
 * @param {boolean} [light] - Whether to return a light color.
 * @return {string} - The color associated with the skill level.
 */
export const pickSkillColor = (level: number, light?: boolean) => {
  if (light) {
    switch (level) {
      case 1:
        return grey[200]

      case 2:
        return amber[50]

      case 3:
        return purple[50]

      case 4:
        return teal[50]

      case 5:
        return green[50]

      default:
        return grey[50]
    }
  }
  switch (level) {
    case 1:
      return grey[600]

    case 2:
      return amber[700]

    case 3:
      return purple[700]

    case 4:
      return teal[700]

    case 5:
      return green[500]

    default:
      return grey[200]
  }
}

/**
 * Updates the date properties of the given item.
 *
 * @param {Object} item - the item to update
 * @returns {void}
 */
export const updateDate = item => {
  if (item.startDate && !(item.startDate instanceof Date)) item.startDate = new Date(item.startDate)
  if (item.endDate && !(item.endDate instanceof Date)) item.endDate = new Date(item.endDate)
}

/**
 * Generates a random color in the format 'rgb(r,g,b, 0.5)'.
 *
 * @return {string} The randomly generated color.
 */
export const randomColor = () => {
  const r = Math.floor(Math.random() * 255)
  const g = Math.floor(Math.random() * 255)
  const b = Math.floor(Math.random() * 255)
  return 'rgb(' + r + ',' + g + ',' + b + ', 0.5)'
}

/**
 * Remove non-Caleo storage items from local storage.
 *
 * @return {void} This function does not return anything.
 */
export const removeNonCaleoStorage = () => {
  const items = { ...localStorage }
  for (const item in items) {
    if (
      typeof item === 'string' &&
      !item.includes('Count') &&
      !item.includes('Columns') &&
      !item.includes('Page') &&
      !item.includes('Visibility') &&
      !item.includes('i18nextLng')
    ) {
      localStorage.removeItem(item)
    }
  }
}

/**
 * Returns an array of used application options.
 *
 * @return {Array<string>} - An array containing the names of the used application options.
 */
export const getUsedApplicationOptions = () => {
  return [
    'Netvisor',
    'Fennoa',
    'Procountor',
    'Fivaldi',
    'Heeros',
    'Asteri',
    'EmCe',
    'Passeli Merit',
    'Mepco',
    'Lemonsoft',
    'Palkkaus.fi',
    'Visma Payroll',
    'Sonet',
    'Maatalousneuvos',
  ]
}
