import { SubAPI } from './API'

/**
 * Permission API.
 * @notExported
 */
class PermissionAPI extends SubAPI {
  /**
   * Get the list of sales organization IDs.
   *
   * @returns Sales organization IDs.
   */
  public getSalesOrganizationIds(controller?: AbortController): Promise<number[]> {
    return this.api.get(`groups/salesOrganizationIds`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get team leader access status.
   *
   * @returns Team leader access status.
   */
  public getTeamLeaderAccess(controller?: AbortController): Promise<boolean> {
    return this.api.get(`groups/teamLeaderAccess`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get team leader access to teams.
   *
   * @param teamIds - Teams to get leader access to.
   * @returns Team leader access statuses.
   */
  public getLeaderAccessToTeams(teamIds?: number[], controller?: AbortController): Promise<number[]> {
    return this.api.post(`groups/teamLeaderAccess`, { teamIds }, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get team leader access to person.
   *
   * @param personId - Person to get leader access to.
   * @returns Team leader access status.
   */
  public getPersonTeamAccess(personId: number, controller?: AbortController): Promise<boolean> {
    return this.api.get(`groups/teamLeader/${personId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get caleo owner status.
   *
   * @returns Caleo owner status.
   */
  public getCaleoOwnerStatus(controller?: AbortController): Promise<boolean> {
    return this.api.get('groups/isCaleoOwner', controller ? { signal: controller.signal } : undefined)
  }
}

export const permissionAPI = new PermissionAPI()
