import { SubAPI } from './API'
import {
  ICombinedNetworkMember,
  INetwork,
  INetworkManager,
  INetworkNotification,
  INetworkRegister,
} from 'types/networkInterfaces'
import { IAccount, IOrganization, IOrganizationQuery } from 'types/userInterfaces'
import { IEmployee } from 'types/adminInterfaces'
import DOMPurify from 'dompurify'
import { SortingState } from '@tanstack/react-table'

/**
 * Network API class.
 * @notExported
 */
class NetworkAPI extends SubAPI {
  /**
   * Check if the user has access to a network.
   *
   * @returns True if the user has access to a network. False otherwise.
   */
  public async checkAccess(controller?: AbortController): Promise<boolean> {
    return await this.api.get('networks/access', controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get all networks.
   *
   * @returns Array of networks.
   */
  public async getNetworks(controller?: AbortController): Promise<INetwork[]> {
    return this.purifyNetworksInfoPage(
      await this.api.get(`networks/`, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Get list of networks.
   *
   * @returns Array of networks.
   */
  public async getNetworksShareList(controller?: AbortController): Promise<INetwork[]> {
    return this.purifyNetworksInfoPage(
      await this.api.get(`networks/list`, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Get searchable networks.
   *
   * @returns Array of networks.
   */
  public async getSearchableNetworks(controller?: AbortController): Promise<INetwork[]> {
    return this.purifyNetworksInfoPage(
      await this.api.get(`networks/searchable`, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Get network by id.
   *
   * @param id - Network ID.
   * @returns Network object.
   */
  public async getNetwork(id: number, controller?: AbortController): Promise<INetwork> {
    return this.purifyNetworkInfoPage(
      await this.api.get(`networks/${id}`, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Create network.
   *
   * @param item - Network details.
   * @returns Created network.
   */
  public async createNetwork(item: Partial<INetwork>, controller?: AbortController): Promise<INetwork> {
    return this.purifyNetworkInfoPage(
      await this.api.post(`networks/`, item, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Update network.
   *
   * @param id - Network ID.
   * @param item - Updated network details.
   * @returns Updated network.
   */
  public async updateNetwork(id: number, item?: unknown, controller?: AbortController): Promise<INetwork> {
    return this.purifyNetworkInfoPage(
      await this.api.put(`networks/${id}`, item, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Delete network.
   *
   * @param networkId - Network ID.
   * @returns Promise of network deletion.
   */
  public async deleteNetwork(networkId: number, controller?: AbortController): Promise<void> {
    return await this.api.delete(`networks/${networkId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Add member to network.
   *
   * @param networkId - Network ID.
   * @param organizationId - Organization ID.
   * @returns Network object.
   */
  public async addMember(networkId: number, organizationId: number, controller?: AbortController): Promise<INetwork> {
    return await this.api.post(
      `networks/member/${networkId}`,
      { organizationId },
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Remove member from network.
   *
   * @param memberId - Member ID.
   * @returns Network object.
   */
  public async removeMember(memberId: number, controller?: AbortController): Promise<INetwork> {
    return this.purifyNetworkInfoPage(
      await this.api.delete(`networks/member/${memberId}`, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Get networks for account.
   *
   * @param accountId - Account ID.
   * @returns Array of networks.
   */
  public async getAccountNetworks(accountId: number, controller?: AbortController): Promise<INetwork[]> {
    return this.purifyNetworksInfoPage(
      await this.api.get(`networks/account/${accountId}`, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Remove account from network.
   *
   * @param memberId - Member ID.
   * @returns Network object.
   */
  public async removeAccount(memberId: number, controller?: AbortController): Promise<INetwork> {
    return this.purifyNetworkInfoPage(
      await this.api.delete(`networks/account/${memberId}`, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Get possible accounts for network manager.
   *
   * @returns Array of accounts.
   */
  public async getPosibleManagers(controller?: AbortController): Promise<INetworkManager[]> {
    return await this.api.get(`networks/managers`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get network accounts.
   *
   * @returns Array of network account.
   */
  public async getAccounts(controller?: AbortController): Promise<IAccount[]> {
    return await this.api.get(`networks/accounts`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get possible accounts for network.
   *
   * @returns Array of accounts.
   */
  public async getPossibleAccounts(controller?: AbortController): Promise<IAccount[]> {
    return await this.api.get(`networks/freelancers`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get broker access networks.
   *
   * @returns Array of networks.
   */
  public async getBrokerAccessNetworks(controller?: AbortController): Promise<INetwork[]> {
    return this.purifyNetworksInfoPage(
      await this.api.get(`networks/broker-access`, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Get organizations for network.
   *
   * @param id - Network ID.
   * @returns One or more organizations.
   */
  public async getNetworkOrganizations(
    id: number | number[],
    controller?: AbortController
  ): Promise<IOrganization | IOrganization[]> {
    return await this.api.post(`networks/organizations`, { id }, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get statistics for network.
   *
   * @param id - Network ID.
   * @returns Array of statistics.
   */
  public async getNetworkStatistics(id: number, controller?: AbortController): Promise<ICombinedNetworkMember[]> {
    return await this.api.get(`networks/statistics/${id}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Register network member.
   *
   * @param networkId - Network ID.
   * @param data - Registeration data.
   * @returns Promise of network member registration or error message.
   */
  public async registerNetworkMember(
    networkId: number,
    data: INetworkRegister,
    controller?: AbortController
  ): Promise<void | string> {
    return await this.api.post(
      `networks/register/${networkId}`,
      data,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Create employee account for network.
   *
   * @param networkId - Network ID.
   * @param data - Employee account data.
   * @returns Created employee.
   */
  public async createEmployee(networkId: number, data, controller?: AbortController): Promise<IAccount> {
    return await this.api.post(
      `networks/employee/${networkId}`,
      data,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Get contact emails for network members.
   *
   * @param networkId - Network ID.
   * @param freelancers - Should freelancers be included?
   * @param organizations - Should organization be included?
   * @returns Blob object.
   */
  public async getContactEmails(networkId: number, freelancers: boolean, organizations: boolean): Promise<Blob> {
    return this.api.get(
      `networks/contacts/${networkId}`,
      {
        responseType: 'blob',
      },
      { freelancers, organizations },
      undefined
    )
  }

  /**
   * Get organizations for network.
   *
   * @param networkId - Network ID.
   * @returns Array of organizations.
   */
  public async getOrganizations(networkId: number, controller?: AbortController): Promise<IOrganization[]> {
    return this.api.get(`networks/organizations/${networkId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Get manage organizations for network.
   *
   * @param networkId - Network ID.
   * @param limit - Items per page.
   * @param page - Page number.
   * @param sorting - Sorted by.
   * @param filter - Search by string.
   * @returns Organization query results.
   */
  public async getManageOrganizations(
    networkId: number,
    limit?: number,
    page?: number,
    sorting?: SortingState,
    filter?: string,
    controller?: AbortController
  ): Promise<IOrganizationQuery> {
    return this.api.get(
      `networks/manage/organizations/${networkId}`,
      controller ? { signal: controller.signal } : undefined,
      {
        limit,
        page,
        sorting,
        filter: filter ? encodeURI(filter) : undefined,
      }
    )
  }

  /**
   * Get freelancers for network.
   *
   * @param networkId - Network ID.
   * @returns Array of freelancer employees.
   */
  public async getNetworkFreelancers(networkId: number, controller?: AbortController): Promise<IEmployee[]> {
    return this.api.get(`networks/freelancers/${networkId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Save network info page.
   *
   * @param data - Network info page data.
   * @returns Updated network.
   */
  public async saveInfo(data: INetwork, controller?: AbortController): Promise<INetwork> {
    return this.purifyNetworkInfoPage(
      await this.api.put(`networks/infopage/${data.id}`, data, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Save proposal guide.
   *
   * @param data - Proposal guide data.
   * @returns Updated network.
   */
  public async saveProposalGuide(data: INetwork, controller?: AbortController): Promise<INetwork> {
    return this.purifyNetworkInfoPage(
      await this.api.put(`networks/proposal/${data.id}`, data, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Get network benefits.
   *
   * @returns Array of benefits for networks.
   */
  public async getNetworkBenefits(controller?: AbortController): Promise<INetwork[]> {
    return this.purifyNetworksInfoPage(
      await this.api.get(`networks/benefits`, controller ? { signal: controller.signal } : undefined)
    )
  }

  /**
   * Check if user is manager for organization.
   *
   * @param organizationId - Organization ID.
   * @returns True if user is manager for organization. False otherwise.
   */
  public async isManagerForOrganization(organizationId: number, controller?: AbortController): Promise<boolean> {
    return this.api.get(`networks/managerFor/${organizationId}`, controller ? { signal: controller.signal } : undefined)
  }

  /**
   * Send network notifications
   *
   * @param data - Notification data
   * @param networkId - Network ID
   * @returns Promise of network notifications sent
   */
  public async sendNotifications(
    data: INetworkNotification,
    networkId: number,
    controller?: AbortController
  ): Promise<void> {
    return this.api.post(
      `networks/notifications/${networkId}`,
      data,
      controller ? { signal: controller.signal } : undefined
    )
  }

  public async removeOrganization(
    networkId: number,
    organizationId: number,
    controller?: AbortController
  ): Promise<void> {
    return this.api.delete(
      `networks/manage/organization/${networkId}/${organizationId}`,
      controller ? { signal: controller.signal } : undefined
    )
  }

  /**
   * Purify network info page.
   *
   * @param rawData - Raw network info page data.
   * @returns Purified network info page data.
   */
  purifyNetworkInfoPage(rawData: INetwork): INetwork {
    const data = { ...rawData }

    if (rawData.translations && rawData.translations.length) {
      for (const tr of rawData.translations) {
        const index = data.translations.findIndex(translation => translation.id === tr.id)
        data.translations[index] = {
          ...tr,
          infoPage: DOMPurify.sanitize(tr.infoPage),
          proposalGuide: DOMPurify.sanitize(tr.proposalGuide),
        }
      }
    }

    return data
  }

  /**
   * Purify multiple networks info page.
   *
   * @param rawData - Array of raw info page data.
   * @returns Array of purified info page data.
   */
  purifyNetworksInfoPage(rawData: INetwork[]): INetwork[] {
    const data = [...rawData]

    for (let item of data) {
      item = this.purifyNetworkInfoPage(item)
    }

    return data
  }
}

export const networkAPI = new NetworkAPI()
