import { Grid, useTheme } from '@mui/material'
import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IAllocation } from 'types/allocationInterfaces'
import CustomTable from 'Components/reusable/Tables/CustomTable'
import { useLocalStorage } from 'hooks/data'
import { ColumnDef, PaginationState, VisibilityState } from '@tanstack/react-table'
import { chooseDBTranslation } from 'utils/translations'
import RemoveIcon from '@mui/icons-material/Remove'
import { sortByDateColumn } from 'Components/reusable/Tables/CustomTable/sortFunctions'
import { convertToDisplayDate } from 'utils/utils'
import CaleoIconButton from 'Components/reusable/IconButtons/CaleoIconButton'
import { useUser } from 'hooks'
import PostAddIcon from '@mui/icons-material/PostAdd'
import { useNavigate } from 'react-router-dom'
import FolderSharedIcon from '@mui/icons-material/FolderShared'

/** @notExported  */
interface ISupplierAllocationProps {
  items: IAllocation[]
}

/**
 * Component for displaying supplier allocations.
 *
 * @returns Supplier allocations component.
 * @notExported
 */
const SupplierAllocations: React.FC<ISupplierAllocationProps> = ({ items }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const { features } = useUser()
  const navigate = useNavigate()

  const [supplierAllocationCount, setSupplierAllocationCount] = useLocalStorage('supplierAllocationCount', 25)
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({})
  const [globalFilter, setGlobalFilter] = React.useState<string>('')

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: supplierAllocationCount ? supplierAllocationCount : 25,
  })

  const columns = useMemo<ColumnDef<IAllocation>[]>(
    () => [
      {
        id: 'assignment',
        header: t(`allocation.${features.includes('assignments') ? 'assignment' : 'workorder'}Name`),
        cell: ({ row }) => {
          if (row.original.Assignment) {
            return chooseDBTranslation(i18n, row.original.Assignment).name
          }
          return <RemoveIcon />
        },
      },
      {
        id: 'roleName',
        header: t('allocation.roleName'),
        cell: ({ row }) => {
          if (row.original.AssignmentRole) {
            return chooseDBTranslation(i18n, row.original.AssignmentRole).name
          }
          return <RemoveIcon />
        },
      },
      {
        id: 'person',
        header: t('allocation.person'),
        cell: ({ row }) => {
          return `${row.original.Person.firstName} ${row.original.Person.lastName}`
        },
      },
      {
        id: 'organization',
        header: t('allocation.organization'),
        cell: ({ row }) => {
          if (row.original.Person.Organization) {
            return chooseDBTranslation(i18n, row.original.Person.Organization).name
          }
          return <RemoveIcon />
        },
      },
      {
        id: 'percent',
        header: t('allocation.percent'),
        accessorFn: row => `${row.percent}`,
        cell: ({ row }) => {
          const value = row.original.type

          let sx
          if (value === 'personal') {
            sx = {
              width: '19px',
              height: '19px',
              borderRadius: '50%',
              display: 'inline-block',
              backgroundColor: theme.palette.secondary.main,
            }
          } else if (row.original.percent >= 100) {
            sx = {
              width: '19px',
              height: '19px',
              borderRadius: '50%',
              display: 'inline-block',
            }
          } else if (row.original.percent < 100 && row.original.percent > 0) {
            sx = {
              width: '19px',
              height: '19px',
              borderRadius: '50%',
              display: 'inline-block',
              backgroundColor: theme.palette.warning.main,
            }
          } else {
            sx = {
              width: '17px',
              height: '17px',
              borderRadius: '50%',
              color: theme.palette.neutral.main,
              display: 'inline-block',
              backgroundColor: theme.palette.background.default,
            }
          }

          return (
            <Grid container>
              <Grid sx={sx} />{' '}
              <Grid
                sx={{
                  marginRight: '25px',
                  marginLeft: '5px',
                }}
              >
                {row.original.percent}
              </Grid>
            </Grid>
          )
        },
      },
      {
        id: 'status',
        header: t('allocation.status'),
        cell: ({ row }) => t(`allocation.state.${row.original.state}`),
      },
      {
        id: 'startDate',
        header: t('allocation.startDate'),
        sortingFn: (rowA, rowB, columnId) => sortByDateColumn(rowA, rowB, columnId),
        accessorFn: row => {
          return convertToDisplayDate(row.startDate)
        },
      },
      {
        id: 'endDate',
        header: t('allocation.endDate'),
        sortingFn: (rowA, rowB, columnId) => sortByDateColumn(rowA, rowB, columnId),
        accessorFn: row => {
          if (row.endDate) {
            return convertToDisplayDate(row.endDate)
          } else {
            return ''
          }
        },
      },
      {
        id: 'controls',
        enableHiding: false,
        cell: ({ row }) => {
          return (
            <>
              <CaleoIconButton
                tooltip={t(`allocation.to${features.includes('assignments') ? 'Assignment' : 'Workorder'}`)}
                clickAction={() => {
                  navigate(
                    `/${features.includes('assignments') ? 'assignments' : 'workorders'}/${row.original.assignmentId}`
                  )
                }}
                icon={<PostAddIcon />}
              />
              <CaleoIconButton
                tooltip={t('allocation.toProposed')}
                clickAction={() => {
                  navigate(
                    `/${features.includes('assignments') ? 'assignments' : 'workorders'}/proposed/${
                      row.original.assignmentId
                    }`
                  )
                }}
                icon={<FolderSharedIcon />}
              />
            </>
          )
        },
      },
    ],
    [i18n, items]
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomTable
          columns={columns}
          data={items}
          search
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          rowCount={supplierAllocationCount}
          setRowCount={setSupplierAllocationCount}
          initialPageSize={10}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          setPagination={setPagination}
          pageIndex={pageIndex}
          pageSize={pageSize}
          elevation={0}
          newStyle
        />
      </Grid>
    </Grid>
  )
}

export default SupplierAllocations
